<!-- 案例、贴吧详情 -->
<template>
  <div>
    <div
      style="
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999;
      "
      v-if="scalBool"
    >
      <van-swipe lazy-render>
        <van-swipe-item v-for="image in images" :key="image">
          <div
            style="
              width: 100%;
              height: 100vh;
              display: flex;
              align-items: center;
            "
            @click="scalBool = false"
          >
            <img style="width: 100%" :src="image" @click.stop />
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div style="width: 100%">
      <van-nav-bar
        title="详情"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
      />
      <div class="msgBox">
        <div
          style="
            width: 100%;
            height: auto;
            overflow: hidden;
            background-color: black;
          "
          v-if="forumData.type == 1"
        >
          <van-swipe lazy-render>
            <van-swipe-item v-for="image in images" :key="image">
              <img
                style="width: 100%; height: 100%"
                :src="image"
                @click="scalBtn"
              />
            </van-swipe-item>
          </van-swipe>
        </div>
        <!-- 视频 -->
        <div
          v-if="forumData.type == 2"
          style="margin-bottom: 10px; height: 36vh"
        >
          <video
            ref="videoPlayerRef"
            class="video-js vjs-default-skin"
            style="width: 100%; height: 100%"
            autoplay
            controls
          ></video>
        </div>
        <div
          v-if="route.query.index == 2"
          style="margin-bottom: 10px; width: 100%; margin-bottom: 10px"
        >
          <file-pdf :pdfSrc="pdfSrc"></file-pdf>
        </div>

        <p
          style="
            padding: 8px 20px 8px 20px;
            font-size: 16px;
            font-weight: bold;
            color: rgb(96, 96, 96);
          "
        >
          {{ forumData.title }}
        </p>
        <div class="van-hairline--top"></div>
        <!-- <p
          style="
            padding: 6px 20px 10px 20px;
            font-size: 13px;
            color: rgb(89, 87, 88);
            white-space: pre-line;
          "
        >
          {{ forumData.content }}
        </p> -->
        <van-text-ellipsis
          style="
            padding: 6px 20px 10px 20px;
            font-size: 13px;
            color: rgb(89, 87, 88);
            white-space: pre-line;
          "
          rows="8"
          :content="forumData.content"
          expand-text="展开"
          collapse-text="收起"
        />
        <div class="van-hairline--top"></div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            padding: 5px 0;
            color: rgb(153, 153, 153);
          "
        >
          <p style="padding: 4px 10px; font-size: 12px">
            作者：<span>{{ forumData.createBy }}</span>
          </p>
          <div style="margin-right: 20px">
            <van-button
              v-show="forumData.collectFlag == 'false'"
              icon="star"
              round
              style="padding: 0 10px"
              size="mini"
              @click="FavoriteBtn()"
              >收藏</van-button
            >
            <van-button
              v-show="forumData.collectFlag != 'false'"
              round
              color="linear-gradient(
    to right,
    rgb(130, 200, 160),
    rgb(130, 200, 160)
  )"
              style="padding: 0 10px"
              icon="star"
              size="mini"
              @click="FavoriteBtn()"
              >已收藏</van-button
            >
            <van-button
              v-show="forumData.likeFlag == 'false'"
              icon="like"
              round
              style="padding: 0 10px"
              size="mini"
              @click="LikeBtn()"
              >点赞</van-button
            >
            <van-button
              v-show="forumData.likeFlag != 'false'"
              round
              color="linear-gradient(
    to right,
    rgb(130, 200, 160),
    rgb(130, 200, 160)
  )"
              style="padding: 0 10px"
              icon="like"
              size="mini"
              @click="LikeBtn()"
              >已点赞</van-button
            >
          </div>
        </div>
        <div class="van-hairline--top"></div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            color: rgb(153, 153, 153);
          "
        >
          <p style="padding: 8px 10px; font-size: 12px">
            共 {{ total }} 条评论
          </p>
          <p style="padding: 8px 10px; font-size: 12px">
            发布时间：{{ forumData.createTime }}
          </p>
        </div>
        <div>
          <div class="comment-view" style="padding: 0px">
            <u-comment :config="config" @submit="submit" @like="like">
              <div v-show="rePlarr.length > 0">
                <div v-for="(item, index) in rePlarr" :key="index">
                  <div
                    class="van-hairline--top"
                    style="padding-bottom: 4px"
                  ></div>
                  <p>
                    <span style="font-size: 13px">{{ item.reviewName }}</span
                    ><span
                      style="
                        font-size: 12px;
                        color: rgb(144, 147, 153);
                        float: right;
                      "
                      >{{ item.reviewTime }}</span
                    >
                  </p>
                  <u-fold unfold line="1">
                    <p style="color: rgb(144, 147, 153)">
                      {{ item.content }}
                    </p>
                  </u-fold>
                  <!-- 子评论 -->
                  <div
                    style="margin-left: 30px"
                    v-for="(item1, index1) in item.children"
                    :key="index1"
                    @click="TomsgBtn(item, 1, item1.reviewName)"
                  >
                    <p>
                      <span style="font-size: 13px">{{ item1.reviewName }}</span
                      ><span
                        style="
                          font-size: 12px;
                          color: rgb(144, 147, 153);
                          float: right;
                        "
                        >{{ item1.reviewTime }}</span
                      >
                    </p>
                    <u-fold unfold line="1">
                      <p style="color: rgb(144, 147, 153)">
                        {{ item1.content }}
                      </p>
                    </u-fold>
                  </div>
                  <p style="width: 100%; height: 40px; position: relative">
                    <van-button
                      style="position: absolute; right: 0; top: 5px"
                      icon="more-o"
                      size="mini"
                      color="linear-gradient(
    to right,
    rgb(130, 200, 160),
    rgb(130, 200, 160)
  )"
                      @click="TomsgBtn(item, 0, '')"
                      >回复</van-button
                    >
                  </p>
                  <div class="van-hairline--top"></div>
                </div>
              </div>
              <div v-show="rePlarr.length == 0">
                <van-empty
                  image="https://fastly.jsdelivr.net/npm/@vant/assets/custom-empty-image.png"
                  image-size="80"
                  description="暂无评论"
                />
              </div>
            </u-comment>
          </div>
        </div>
      </div>
      <van-tabbar v-show="inputBool">
        <van-field
          v-model="plvalue"
          clearable
          label="回复评论："
          left-icon="comment"
          :placeholder="placemsg"
          autocomplete="off"
          style="color: rgb(96, 96, 96)"
        >
          <template #button>
            <van-button
              v-if="textBool"
              size="small"
              color="linear-gradient(
    to right,
    rgb(130, 200, 160),
    rgb(130, 200, 160)
  )"
              @click="childTomsg"
              >发送</van-button
            >
          </template></van-field
        >
      </van-tabbar>
    </div>
  </div>
</template>
<script setup lang="ts">
import { showToast } from "vant";
import { useRoute } from "vue-router";
import { nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import {
  forumList,
  caseList,
  Msgdata1,
  Msgdata2,
  getwenMag,
  commentApi,
  addcommentApi,
  TbcommentApi,
  TbaddcommentApi,
  collLikeApi,
  cancelCollLikeApi,
  getwenReviewMag,
  addwenReviewMag,
} from "@/api/forum";
//
import { reactive } from "vue";
import {
  UToast,
  CommentApi,
  CommentSubmitParam,
  ConfigApi,
  str,
} from "undraw-ui";
import emoji from "./emoji";
import { fa } from "element-plus/es/locale";
import flvjs from "flv.js";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import FilePdf from "@/components/fileWord/FilePdf.vue";
//
let route = useRoute();
console.log(route.query.value); //query传参
const plvalue = ref("");
const textBool = ref(false);
const inputBool = ref(false);
const scalBool = ref(false);
const images = ref([]);
const rePlarr = ref([]);
const total = ref(0);
const pdfSrc = ref("");
const onClickLeft = () => history.back();
watch(plvalue, (newValue, oldValue) => {
  if (newValue == "") {
    textBool.value = false;
  } else {
    textBool.value = true;
  }
});
const caseData = ref([]);
const forumData = ref([]);

const videoPlayerRef = ref();
const myPlayer = ref();

onMounted(() => {
  if (route.query.index == 0) {
    getcaseList();
  }
  if (route.query.index == 1) {
    getforumList();
  }
  if (route.query.index == 2) {
    getwenList();
  }
});

onUnmounted(() => {
  if (myPlayer.value) {
    myPlayer.value.dispose();
  }
});

//初始化播放
const startVideo = () => {
  nextTick(() => {
    myPlayer.value = videojs(videoPlayerRef.value, {
      // poster: "//vjs.zencdn.net/v/oceans.png",//视频封面照片
      controls: true, //视频控件
      autoplay: true, //自动播放
      sources: [
        {
          src: forumData.value.filesList[0].url, //播放视频地址
          // src: `https://stream7.iqilu.com/10339/upload_transcode/202002/18/20200218114723HDu3hhxqIT.mp4`, //播放视频地址
          // type: "application/x-mpegURL", //播放m3u8需要设置的格式
        },
      ],
      controlBar: {
        remainingTimeDisplay: {
          displayNegative: false,
        },
      },
      playbackRates: [0.5, 1, 1.5, 2], //设置播放速度
    });
  });
};

//案例列表
const getcaseList = () => {
  let data = {
    caseId: route.query.value,
  };
  //
  Msgdata2(route.query.value).then((res) => {
    if (res.status == 200) {
      console.log(res);
      images.value = [];
      forumData.value = res.data.data;
      res.data.data.filesList.forEach((item: any) => {
        images.value.push(item.url);
      });
      // images.value = res.data.data.filesList;
      if (forumData.value.type == 2) {
        startVideo();
      }
    }
  });
  //评论列表
  commentApi(data).then((res) => {
    rePlarr.value = res.data.rows;
    total.value = res.data.total;
  });
};
//贴吧列表
const getforumList = () => {
  let data = {
    literatureId: route.query.value,
  };
  Msgdata1(route.query.value).then((res) => {
    console.log(res);
    images.value = [];
    if (res.status == 200) {
      forumData.value = res.data.data;
      res.data.data.filesList.forEach((item: any) => {
        images.value.push(item.url);
      });
      // images.value = res.data.data.filesList;

      if (forumData.value.type == 2) {
        startVideo();
      }
    }
  });
  //贴吧评论列表
  TbcommentApi(data).then((res) => {
    console.log("ff", res);
    rePlarr.value = res.data.rows;
    total.value = res.data.total;
  });
};

//文献列表
const getwenList = () => {
  let data = {
    literatureId: route.query.value,
  };
  getwenMag(route.query.value).then((res) => {
    console.log(res);
    if (res.status == 200) {
      forumData.value = res.data.data;
      pdfSrc.value = res.data.data.literatureFileNameUrl;
    }
  });
  //文献评论列表
  getwenReviewMag(data).then((res) => {
    console.log(res);
    rePlarr.value = res.data.rows;
    total.value = res.data.total;
  });
};

//评论区
const config = reactive<ConfigApi>({
  user: {
    id: 1,
    username: "admin",
    avatar: localStorage.getItem("userImage"),
    likes: [1, 2, 11],
  },
  emoji: emoji,
  comments: [],
});
// 提交评论事件
const submit = ({ clear, content, parentId }: CommentSubmitParam) => {
  if (route.query.index == 0) {
    let data = {
      content: content,
      caseId: route.query.value,
    };
    addcommentApi(data).then((res) => {
      if (res.data.code == 200) {
        //评论区
        let data1 = {
          caseId: route.query.value,
        };
        commentApi(data1).then((res) => {
          rePlarr.value = res.data.rows;
          total.value = res.data.total;
          const text = document.querySelector(".rich-input");
          text.innerHTML = "";
        });
      }
    });
    // UToast({ message: '评论成功!', type: 'info' })
    // 提交评论 --后端接口处理
    // editSubmit(content, parentId as number)
    // clear()
  }
  if (route.query.index == 1) {
    let data = {
      content: content,
      postBarId: route.query.value,
    };
    TbaddcommentApi(data).then((res) => {
      if (res.data.code == 200) {
        //评论区
        let data1 = {
          postBarId: route.query.value,
        };
        TbcommentApi(data1).then((res) => {
          rePlarr.value = res.data.rows;
          total.value = res.data.total;
          const text = document.querySelector(".rich-input");
          text.innerHTML = "";
        });
      }
    });
  }
  if (route.query.index == 2) {
    let data = {
      content: content,
      literatureId: route.query.value,
    };
    addwenReviewMag(data).then((res) => {
      if (res.data.code == 200) {
        //评论区
        let data1 = {
          literatureId: route.query.value,
        };
        getwenReviewMag(data1).then((res) => {
          rePlarr.value = res.data.rows;
          total.value = res.data.total;
          const text = document.querySelector(".rich-input");
          text.innerHTML = "";
        });
      }
    });
  }
};
const _parentId = ref("");
const _replyName = ref("");
const placemsg = ref("请输入回复内容");
const contoMsg = ref("");
//回复评论
const TomsgBtn = (value: any, id: number, name: any) => {
  _parentId.value = value.reviewId;
  _replyName.value = value.reviewName;
  inputBool.value = true;
  let input = document.querySelector(".van-field__control");
  if (id == 1) {
    placemsg.value = `回复 ${name}： `;
    contoMsg.value = `回复 ${name}： `;
  } else {
    plvalue.value = "";
  }
  // 获取输入框的值的长度
  // let length = input.value.length;
  // // 使用setSelectionRange()方法将焦点设置到文本末尾
  // input.setSelectionRange(length, length);
  nextTick(() => {
    input.focus();
    input.addEventListener("blur", function () {
      setTimeout(() => {
        inputBool.value = false;
      }, 100);
    });
  });
};
// 收藏
const FavoriteBtn = () => {
  if (route.query.index == 0) {
    if (forumData.value.collectFlag == "false") {
      let data = {
        businessId: route.query.value,
        businessType: Number(route.query.index) + 1,
        operatorType: 3,
      };
      collLikeApi(data).then((res) => {
        forumData.value.collectFlag = "true";
      });
    } else {
      let data = {
        businessId: route.query.value,
        businessType: Number(route.query.index) + 1,
        operatorType: 3,
      };
      cancelCollLikeApi(data).then((res) => {
        forumData.value.collectFlag = "false";
      });
    }
  }
  if (route.query.index == 1) {
    if (forumData.value.collectFlag == "false") {
      let data = {
        businessId: route.query.value,
        businessType: Number(route.query.index) + 1,
        operatorType: 3,
      };
      collLikeApi(data).then((res) => {
        forumData.value.collectFlag = "true";
      });
    } else {
      let data = {
        businessId: route.query.value,
        businessType: Number(route.query.index) + 1,
        operatorType: 3,
      };
      cancelCollLikeApi(data).then((res) => {
        forumData.value.collectFlag = "false";
      });
    }
  }
  if (route.query.index == 2) {
    if (forumData.value.collectFlag == "false") {
      let data = {
        businessId: route.query.value,
        businessType: Number(route.query.index) + 1,
        operatorType: 3,
      };
      collLikeApi(data).then((res) => {
        forumData.value.collectFlag = "true";
      });
    } else {
      let data = {
        businessId: route.query.value,
        businessType: Number(route.query.index) + 1,
        operatorType: 3,
      };
      cancelCollLikeApi(data).then((res) => {
        forumData.value.collectFlag = "false";
      });
    }
  }
};
// 点赞
const LikeBtn = () => {
  if (route.query.index == 0) {
    if (forumData.value.likeFlag == "false") {
      let data = {
        businessId: route.query.value,
        businessType: Number(route.query.index) + 1,
        operatorType: 2,
      };
      collLikeApi(data).then((res) => {
        forumData.value.likeFlag = "true";
      });
    } else {
      let data = {
        businessId: route.query.value,
        businessType: Number(route.query.index) + 1,
        operatorType: 2,
      };
      cancelCollLikeApi(data).then((res) => {
        forumData.value.likeFlag = "false";
      });
    }
  }
  if (route.query.index == 1) {
    if (forumData.value.likeFlag == "false") {
      let data = {
        businessId: route.query.value,
        businessType: Number(route.query.index) + 1,
        operatorType: 2,
      };
      collLikeApi(data).then((res) => {
        forumData.value.likeFlag = "true";
      });
    } else {
      let data = {
        businessId: route.query.value,
        businessType: Number(route.query.index) + 1,
        operatorType: 2,
      };
      cancelCollLikeApi(data).then((res) => {
        forumData.value.likeFlag = "false";
      });
    }
  }
  if (route.query.index == 2) {
    if (forumData.value.likeFlag == "false") {
      let data = {
        businessId: route.query.value,
        businessType: Number(route.query.index) + 1,
        operatorType: 2,
      };
      collLikeApi(data).then((res) => {
        forumData.value.likeFlag = "true";
      });
    } else {
      let data = {
        businessId: route.query.value,
        businessType: Number(route.query.index) + 1,
        operatorType: 2,
      };
      cancelCollLikeApi(data).then((res) => {
        forumData.value.likeFlag = "false";
      });
    }
  }
};

//发送回复
const childTomsg = () => {
  inputBool.value = false;
  if (route.query.index == 0) {
    let data = {
      content: contoMsg.value + plvalue.value,
      caseId: route.query.value,
      parentId: _parentId.value,
      replyName: _replyName.value,
    };
    addcommentApi(data).then((res) => {
      if (res.data.code == 200) {
        //评论区
        let data1 = {
          caseId: route.query.value,
        };
        commentApi(data1).then((res) => {
          rePlarr.value = res.data.rows;
          total.value = res.data.total;
          plvalue.value = undefined;
        });
      }
    });
  }
  if (route.query.index == 1) {
    let data = {
      content: contoMsg.value + plvalue.value,
      postBarId: route.query.value,
      parentId: _parentId.value,
      replyName: _replyName.value,
    };
    TbaddcommentApi(data).then((res) => {
      if (res.data.code == 200) {
        //评论区
        let data1 = {
          postBarId: route.query.value,
        };
        TbcommentApi(data1).then((res) => {
          rePlarr.value = res.data.rows;
          total.value = res.data.total;
          plvalue.value = undefined;
        });
      }
    });
  }
  if (route.query.index == 2) {
    let data = {
      content: contoMsg.value + plvalue.value,
      literatureId: route.query.value,
      parentId: _parentId.value,
      replyName: _replyName.value,
    };
    addwenReviewMag(data).then((res) => {
      if (res.data.code == 200) {
        //评论区
        let data1 = {
          literatureId: route.query.value,
        };
        getwenReviewMag(data1).then((res) => {
          rePlarr.value = res.data.rows;
          total.value = res.data.total;
          plvalue.value = undefined;
        });
      }
    });
  }
};

const scalBtn = () => {
  scalBool.value = true;
};
</script>

<style scoped>
.msgBox {
  width: 100%;
  height: calc(100vh - 50px);
  overflow-y: scroll;
}
.u-comment[data-v-46e72c8f] {
  margin-top: 0;
}
.van-swipe-item {
  background-color: black !important;
}
img {
  object-fit: contain;
}
::v-deep .van-swipe__track {
  align-items: center !important;
}
</style>
