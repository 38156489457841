<!-- 案例、贴吧发布 -->
<template>
  <div>
    <div style="width: 100%; height: 70vh">
      <van-nav-bar
        :title="reTitle"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
      />
      <div style="padding-bottom: 10px">
        <van-form @submit="onSubmit">
          <van-cell-group inset>
            <van-field
              v-model="uptitle"
              autocomplete="off"
              label="标题"
              placeholder="请输入标题"
              maxlength="30"
              :rules="[{ required: true, message: '请填写标题' }]"
            />

            <van-field
              v-if="
                route.query.value == 'wen' ||
                route.query.value == 1 ||
                route.query.value == 3
              "
              v-model="upmsg"
              rows="4"
              :max-height="100"
              maxlength="1000"
              label="描述"
              type="textarea"
              placeholder="请输入描述"
              show-word-limit
              :rules="[{ required: true, message: '请填写描述' }]"
              input-class="textarea"
            />
            <van-field
              v-if="route.query.value == 0 || route.query.value == 2"
              v-model="upmsg1"
              rows="1"
              :max-height="100"
              maxlength="100"
              label="基本数据"
              type="textarea"
              placeholder="请输入内容"
              show-word-limit
              :rules="[{ required: true, message: '请填写内容' }]"
              input-class="textarea"
            />
            <van-field
              v-if="route.query.value == 0 || route.query.value == 2"
              v-model="upmsg2"
              rows="2"
              :max-height="100"
              maxlength="300"
              label="入院原因及主要治疗经过"
              type="textarea"
              placeholder="请输入内容"
              show-word-limit
              :rules="[{ required: true, message: '请填写内容' }]"
              input-class="textarea"
            />
            <van-field
              v-if="route.query.value == 0 || route.query.value == 2"
              v-model="upmsg3"
              rows="2"
              :max-height="100"
              maxlength="200"
              label="目前身体状况主诉患者:"
              type="textarea"
              placeholder="请输入内容"
              show-word-limit
              :rules="[{ required: true, message: '请填写内容' }]"
              input-class="textarea"
            />
            <van-field
              v-if="route.query.value == 0 || route.query.value == 2"
              v-model="upmsg4"
              rows="2"
              :max-height="100"
              maxlength="200"
              label="身体评估、心理社会评估"
              type="textarea"
              placeholder="请输入内容"
              show-word-limit
              :rules="[{ required: true, message: '请填写内容' }]"
              input-class="textarea"
            />
            <van-field
              v-if="route.query.value == 0 || route.query.value == 2"
              v-model="upmsg5"
              rows="2"
              :max-height="100"
              maxlength="100"
              label="家族史"
              type="textarea"
              placeholder="请输入内容"
              show-word-limit
              :rules="[{ required: true, message: '请填写内容' }]"
              input-class="textarea"
            />
            <van-field
              v-if="route.query.value == 0 || route.query.value == 2"
              v-model="upmsg6"
              rows="2"
              :max-height="100"
              maxlength="100"
              label="既往史"
              type="textarea"
              placeholder="请输入内容"
              show-word-limit
              :rules="[{ required: true, message: '请填写内容' }]"
              input-class="textarea"
            />
            <van-field
              v-if="route.query.value == 0 || route.query.value == 2"
              v-model="upmsg7"
              rows="2"
              :max-height="100"
              maxlength="200"
              label="实验室及其他检查结果"
              type="textarea"
              placeholder="请输入内容"
              show-word-limit
              :rules="[{ required: true, message: '请填写内容' }]"
              input-class="textarea"
            />
            <van-field
              name="radio"
              label="类型"
              v-if="route.query.value != 'wen'"
              style="width: 96vw"
            >
              <template #input>
                <van-radio-group v-model="checked" direction="horizontal">
                  <van-radio name="1">图文</van-radio>
                  <van-radio name="2">视频</van-radio>
                  <van-radio
                    name="3"
                    v-if="route.query.value == 0 || route.query.value == 2"
                    >文本</van-radio
                  >
                  <!-- <van-radio name="4">手术方案</van-radio> -->
                </van-radio-group>
              </template>
            </van-field>
            <van-field
              v-model="result"
              is-link
              readonly
              name="type"
              label="科室类型"
              placeholder="点击选择科室类型"
              @click="showPicker = true"
              :rules="[{ required: true, message: '请选择科室名称' }]"
            />
            <van-popup v-model:show="showPicker" position="bottom">
              <van-picker
                :columns="columns"
                @confirm="onConfirm"
                @cancel="showPicker = false"
              />
            </van-popup>
            <van-field name="radio" label="是否公开">
              <template #input>
                <van-radio-group v-model="openchecked" direction="horizontal">
                  <van-radio name="0">公开</van-radio>
                  <van-radio name="1">非公开</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field
              v-if="checked == 1 && route.query.value != 'wen'"
              name="uploader"
              label="图片上传"
            >
              <template #input>
                <van-uploader
                  max-count="9"
                  v-model="filevalue"
                  :multiple="true"
                  :max-size="20 * 1024 * 1024"
                  :after-read="afterRead"
                  :before-delete="beforeDeleteBack"
                  accept="image/jpeg,image/png"
                  @oversize="onOversize"
                />
              </template>
            </van-field>
            <van-field
              v-if="checked == 2 && route.query.value != 'wen'"
              name="uploader"
              label="视频上传"
            >
              <template #input>
                <div style="width: 50%">
                  <input
                    type="file"
                    accept=".mp4, .webm"
                    max-size="100mb"
                    @change="getBigectURL($event)"
                  />
                </div>
                <video
                  :src="videoSrc"
                  controls="controls"
                  width="120"
                  height="100"
                ></video>
              </template>
            </van-field>
            <van-field
              v-if="checked == 3 || route.query.value == 'wen'"
              name="uploader"
              label="文件上传"
            >
              <template #input>
                <p style="width: 60px"></p>
                <input
                  type="file"
                  accept=".pdf"
                  max-size="50mb"
                  @change="getFileURL($event)"
                />
              </template>
            </van-field>
            <van-field
              v-if="route.query.value == 'wen'"
              name="uploader"
              label="封面上传"
            >
              <template #input>
                <van-uploader v-model="cover" :max-count="1" />
              </template>
            </van-field>
            <van-field v-if="checked == 3" name="uploader" label="封面上传">
              <template #input>
                <van-uploader v-model="cover" :max-count="1" />
              </template>
            </van-field>
          </van-cell-group>
          <div style="margin: 16px">
            <van-button
              round
              block
              type="primary"
              native-type="submit"
              v-loading.fullscreen.lock="uploadBool"
              color="rgb(176,217,157)"
            >
              发布
            </van-button>
          </div>
        </van-form>
      </div>
      <div></div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { showToast } from "vant";
import { onMounted, onUnmounted, ref, watch } from "vue";
import {
  addforumList,
  addcaseList,
  Msgdata1,
  Msgdata2,
  putforumList,
  putcaseList,
  addwenList,
} from "@/api/forum";
const onClickLeft = () => history.back();
import { useRoute } from "vue-router";
// import { any } from "video.js/dist/types/utils/events";
let route = useRoute();
const filevalue = ref([]);
const delfile = ref([]);
const delfiles = ref([]);
const upmsg = ref("");
const upmsg1 = ref("");
const upmsg2 = ref("");
const upmsg3 = ref("");
const upmsg4 = ref("");
const upmsg5 = ref("");
const upmsg6 = ref("");
const upmsg7 = ref("");
const uptitle = ref("");
const checked = ref("1");
const cover = ref([]);
const openchecked = ref("0");
const reTitle = ref("");
const fileList = ref([]);
const uploadBool = ref(false);
const videoSrc: any = ref("");
const videoFile: any = ref("");
const txtFile = ref("");
const columns = ref([
  { text: "胸外科", value: 0 },
  { text: "肝胆外科", value: 1 },
  { text: "神经外科", value: 2 },
  { text: "妇科", value: 3 },
  { text: "泌尿外科", value: 4 },
  { text: "骨科", value: 5 },
  { text: "小儿外科", value: 6 },
]);

const result = ref("");
const result2 = ref("");
const showPicker = ref(false);
const onConfirm = ({ selectedOptions }) => {
  result.value = selectedOptions[0]?.text;
  result2.value = selectedOptions[0]?.value;
  showPicker.value = false;
};
watch(upmsg1, (newval, oldval) => {
  upmsg.value =
    "基本数据:" +
    newval +
    "\n" +
    "入院原因及主要治疗经过:" +
    upmsg2.value +
    "\n" +
    "目前身体状况主诉患者:" +
    upmsg3.value +
    "\n" +
    "身体评估、心理社会评估:" +
    upmsg4.value +
    "\n" +
    "家族史:" +
    upmsg5.value +
    "\n" +
    "既往史:" +
    upmsg6.value +
    "\n" +
    "实验室及其他检查结果:" +
    upmsg7.value +
    "\n";
});
watch(upmsg2, (newval, oldval) => {
  upmsg.value =
    "基本数据:" +
    upmsg1.value +
    "\n" +
    "入院原因及主要治疗经过:" +
    newval +
    "\n" +
    "目前身体状况主诉患者:" +
    upmsg3.value +
    "\n" +
    "身体评估、心理社会评估:" +
    upmsg4.value +
    "\n" +
    "家族史:" +
    upmsg5.value +
    "\n" +
    "既往史:" +
    upmsg6.value +
    "\n" +
    "实验室及其他检查结果:" +
    upmsg7.value +
    "\n";
});
watch(upmsg3, (newval, oldval) => {
  upmsg.value =
    "基本数据:" +
    upmsg1.value +
    "\n" +
    "入院原因及主要治疗经过:" +
    upmsg2.value +
    "\n" +
    "目前身体状况主诉患者:" +
    newval +
    "\n" +
    "身体评估、心理社会评估:" +
    upmsg4.value +
    "\n" +
    "家族史:" +
    upmsg5.value +
    "\n" +
    "既往史:" +
    upmsg6.value +
    "\n" +
    "实验室及其他检查结果:" +
    upmsg7.value +
    "\n";
});
watch(upmsg4, (newval, oldval) => {
  upmsg.value =
    "基本数据:" +
    upmsg1.value +
    "\n" +
    "入院原因及主要治疗经过:" +
    upmsg2.value +
    "\n" +
    "目前身体状况主诉患者:" +
    upmsg3.value +
    "\n" +
    "身体评估、心理社会评估:" +
    newval +
    "\n" +
    "家族史:" +
    upmsg5.value +
    "\n" +
    "既往史:" +
    upmsg6.value +
    "\n" +
    "实验室及其他检查结果:" +
    upmsg7.value +
    "\n";
});
watch(upmsg5, (newval, oldval) => {
  upmsg.value =
    "基本数据:" +
    upmsg1.value +
    "\n" +
    "入院原因及主要治疗经过:" +
    upmsg2.value +
    "\n" +
    "目前身体状况主诉患者:" +
    upmsg3.value +
    "\n" +
    "身体评估、心理社会评估:" +
    upmsg4.value +
    "\n" +
    "家族史:" +
    newval +
    "\n" +
    "既往史:" +
    upmsg6.value +
    "\n" +
    "实验室及其他检查结果:" +
    upmsg7.value +
    "\n";
});
watch(upmsg6, (newval, oldval) => {
  upmsg.value =
    "基本数据:" +
    upmsg1.value +
    "\n" +
    "入院原因及主要治疗经过:" +
    upmsg2.value +
    "\n" +
    "目前身体状况主诉患者:" +
    upmsg3.value +
    "\n" +
    "身体评估、心理社会评估:" +
    upmsg4.value +
    "\n" +
    "家族史:" +
    upmsg5.value +
    "\n" +
    "既往史:" +
    newval +
    "\n" +
    "实验室及其他检查结果:" +
    upmsg7.value +
    "\n";
});
watch(upmsg7, (newval, oldval) => {
  upmsg.value =
    "基本数据:" +
    upmsg1.value +
    "\n" +
    "入院原因及主要治疗经过:" +
    upmsg2.value +
    "\n" +
    "目前身体状况主诉患者:" +
    upmsg3.value +
    "\n" +
    "身体评估、心理社会评估:" +
    upmsg4.value +
    "\n" +
    "家族史:" +
    upmsg5.value +
    "\n" +
    "既往史:" +
    upmsg6.value +
    "\n" +
    "实验室及其他检查结果:" +
    newval +
    "\n";
});
onMounted(() => {
  if (route.query.value == 0) {
    reTitle.value = "发布（案例）";
  }
  if (route.query.value == 1) {
    reTitle.value = "发布（贴吧）";
  }
  if (route.query.value == "wen") {
    reTitle.value = "发布（文献）";
  }

  if (route.query.value == 2) {
    reTitle.value = "编辑（案例）";
    Msgdata2(route.query.id).then((res) => {
      if (res.data.code == 200) {
        let imgarr = res.data.data.filesList;
        delfiles.value = imgarr;
        if (res.data.data.type == 1) {
          imgarr.forEach((item: any) => {
            filevalue.value.push({
              url: item.url,
            });
          });
        }
        if (res.data.data.type == 2) {
          videoSrc.value = imgarr[0].url;
          videoFile.value = imgarr[0].url;
        }
        uptitle.value = res.data.data.title;
        checked.value = res.data.data.type;
        openchecked.value = res.data.data.isOpen;
        columns.value.forEach((item) => {
          if (item.value == res.data.data.departmentType) {
            result.value = item.text;
            result2.value = item.value;
          }
        });
        let content = res.data.data.content;
        //1
        const regex = /:([^'\n']+)/;
        const match = content.match(regex);
        const str = match ? match[1] : "";
        str.replace(/[\r\n]/g, "");
        upmsg1.value = str;

        // const regex1 = /^[^:]*:(.*)/;
        // const match1 = upmsg1.value.match(regex1);
        // upmsg1.value = match1 ? match1[1] : "";
        //2
        const regex21 = /^[^\n]*\n([^\n]*)\n/;
        const match21 = content.match(regex21);
        upmsg2.value = match21 ? match21[1] : "";
        const regex22 = /^[^:]*:(.*)/;
        const match22 = upmsg2.value.match(regex22);
        upmsg2.value = match22 ? match22[1] : "";

        //3
        const regex31 = /^[^\n]*\n[^\n]*\n([^\n]*)\n/;
        const match31 = content.match(regex31);
        upmsg3.value = match31 ? match31[1] : "";
        const regex32 = /^[^:]*:(.*)/;
        const match32 = upmsg3.value.match(regex32);
        upmsg3.value = match32 ? match32[1] : "";

        //4 [^\n]*\n
        const regex41 = /^[^\n]*\n[^\n]*\n[^\n]*\n([^\n]*)\n/;
        const match41 = content.match(regex41);
        upmsg4.value = match41 ? match41[1] : "";
        const regex42 = /^[^:]*:(.*)/;
        const match42 = upmsg4.value.match(regex42);
        upmsg4.value = match42 ? match42[1] : "";
        //5 [^\n]*\n
        const regex51 = /^[^\n]*\n[^\n]*\n[^\n]*\n[^\n]*\n([^\n]*)\n/;
        const match51 = content.match(regex51);
        upmsg5.value = match51 ? match51[1] : "";
        const regex52 = /^[^:]*:(.*)/;
        const match52 = upmsg5.value.match(regex52);
        upmsg5.value = match52 ? match52[1] : "";
        //6 [^\n]*\n
        const regex61 = /^[^\n]*\n[^\n]*\n[^\n]*\n[^\n]*\n[^\n]*\n([^\n]*)\n/;
        const match61 = content.match(regex61);
        upmsg6.value = match61 ? match61[1] : "";
        const regex62 = /^[^:]*:(.*)/;
        const match62 = upmsg6.value.match(regex62);
        upmsg6.value = match62 ? match62[1] : "";
        //7 [^\n]*\n
        const regex71 =
          /^[^\n]*\n[^\n]*\n[^\n]*\n[^\n]*\n[^\n]*\n[^\n]*\n([^\n]*)\n/;
        const match71 = content.match(regex71);
        upmsg7.value = match71 ? match71[1] : "";
        const regex72 = /^[^:]*:(.*)/;
        const match72 = upmsg7.value.match(regex72);
        upmsg7.value = match72 ? match72[1] : "";
      }
    });
  }
  if (route.query.value == 3) {
    reTitle.value = "编辑（贴吧）";
    Msgdata1(route.query.id).then((res) => {
      console.log(res);
      if (res.data.code == 200) {
        let imgarr = res.data.data.filesList;
        delfiles.value = imgarr;
        if (res.data.data.type == 1) {
          imgarr.forEach((item: any) => {
            filevalue.value.push({
              url: item.url,
            });
          });
        }
        if (res.data.data.type == 2) {
          videoSrc.value = imgarr[0].url;
          videoFile.value = imgarr[0].url;
        }
        uptitle.value = res.data.data.title;
        checked.value = res.data.data.type;
        openchecked.value = res.data.data.isOpen;
        columns.value.forEach((item) => {
          if (item.value == res.data.data.departmentType) {
            result.value = item.text;
            result2.value = item.value;
          }
        });
        let content = res.data.data.content;
        upmsg.value = content;
        // //1
        // const regex = /:([^'\n']+)/;
        // const match = content.match(regex);
        // const str = match ? match[1] : "";
        // str.replace(/[\r\n]/g, "");
        // upmsg1.value = str;
        // //2
        // const regex21 = /^[^\n]*\n([^\n]*)\n/;
        // const match21 = content.match(regex21);
        // upmsg2.value = match21 ? match21[1] : "";
        // const regex22 = /^[^:]*:(.*)/;
        // const match22 = upmsg2.value.match(regex22);
        // upmsg2.value = match22 ? match22[1] : "";

        // //3
        // const regex31 = /^[^\n]*\n[^\n]*\n([^\n]*)\n/;
        // const match31 = content.match(regex31);
        // upmsg3.value = match31 ? match31[1] : "";
        // const regex32 = /^[^:]*:(.*)/;
        // const match32 = upmsg3.value.match(regex32);
        // upmsg3.value = match32 ? match32[1] : "";

        // //4 [^\n]*\n
        // const regex41 = /^[^\n]*\n[^\n]*\n[^\n]*\n([^\n]*)\n/;
        // const match41 = content.match(regex41);
        // upmsg4.value = match41 ? match41[1] : "";
        // const regex42 = /^[^:]*:(.*)/;
        // const match42 = upmsg4.value.match(regex42);
        // upmsg4.value = match42 ? match42[1] : "";
        // //5 [^\n]*\n
        // const regex51 = /^[^\n]*\n[^\n]*\n[^\n]*\n[^\n]*\n([^\n]*)\n/;
        // const match51 = content.match(regex51);
        // upmsg5.value = match51 ? match51[1] : "";
        // const regex52 = /^[^:]*:(.*)/;
        // const match52 = upmsg5.value.match(regex52);
        // upmsg5.value = match52 ? match52[1] : "";
        // //6 [^\n]*\n
        // const regex61 = /^[^\n]*\n[^\n]*\n[^\n]*\n[^\n]*\n[^\n]*\n([^\n]*)\n/;
        // const match61 = content.match(regex61);
        // upmsg6.value = match61 ? match61[1] : "";
        // const regex62 = /^[^:]*:(.*)/;
        // const match62 = upmsg6.value.match(regex62);
        // upmsg6.value = match62 ? match62[1] : "";
        // //7 [^\n]*\n
        // const regex71 =
        //   /^[^\n]*\n[^\n]*\n[^\n]*\n[^\n]*\n[^\n]*\n[^\n]*\n([^\n]*)\n/;
        // const match71 = content.match(regex71);
        // upmsg7.value = match71 ? match71[1] : "";
        // const regex72 = /^[^:]*:(.*)/;
        // const match72 = upmsg7.value.match(regex72);
        // upmsg7.value = match72 ? match72[1] : "";
      }
    });
  }
});
// 提交
const onSubmit = (values) => {
  if (route.query.value == 0) {
    if (filevalue.value.length == 0 && checked.value == 1) {
      showToast("请上传图片");
    } else if (videoSrc.value == "" && checked.value == 2) {
      showToast("请上传视频");
    } else if (txtFile.value == "" && checked.value == 3) {
      showToast("请上传文件");
    } else {
      uploadBool.value = true;
      let formData = new FormData();
      if (checked.value == 1) {
        filevalue.value.forEach((item) => {
          formData.append("files", item.file);
        });
      }
      if (checked.value == 2) {
        formData.append("files", videoFile.value);
      }
      if (checked.value == 3) {
        formData.append("files", txtFile.value);
        cover.value.forEach((item) => {
          formData.append("fileCoverPictureUrl", item.file);
        });
      }

      formData.append("title", uptitle.value);
      formData.append("departmentType", result2.value);
      formData.append("content", upmsg.value);
      formData.append("type", checked.value);
      formData.append("isOpen", openchecked.value);

      addcaseList(formData)
        .then((res) => {
          uploadBool.value = false;
          if (res.data.code == 200) {
            showToast("上传案例成功");
            history.back();
          }
        })
        .catch((err) => {
          uploadBool.value = false;
        });
    }
  }
  if (route.query.value == 1) {
    if (filevalue.value.length == 0 && checked.value == 1) {
      showToast("请上传图片");
    } else if (videoSrc.value == "" && checked.value == 2) {
      showToast("请上传视频");
    } else {
      uploadBool.value = true;
      let formData = new FormData();
      if (checked.value == 1) {
        filevalue.value.forEach((item) => {
          formData.append("files", item.file);
        });
      }
      if (checked.value == 2) {
        formData.append("files", videoFile.value);
      }

      formData.append("title", uptitle.value);
      formData.append("departmentType", result2.value);
      formData.append("content", upmsg.value);
      formData.append("type", checked.value);
      formData.append("isOpen", openchecked.value);
      addforumList(formData).then((res) => {
        uploadBool.value = false;
        if (res.data.code == 200) {
          showToast("上传贴吧成功");
          history.back();
        }
      });
    }
  }
  // 编辑
  if (route.query.value == 2) {
    if (filevalue.value.length == 0 && checked.value == 1) {
      showToast("请上传图片");
    } else if (videoSrc.value == "" && checked.value == 2) {
      showToast("请上传视频");
    } else {
      uploadBool.value = true;
      let formData = new FormData();
      //图文
      if (checked.value == 1) {
        if (delfile.value.length > 0) {
          let arr: any = [];
          delfile.value.forEach((item) => {
            delfiles.value.forEach((item1) => {
              if (item.url == item1.url) {
                arr.push(item1.id);
              }
            });
          });

          formData.append("delList", arr.join(","));
        } else {
          formData.append("delList", []);
        }
        filevalue.value.forEach((item) => {
          if ("file" in item) {
            formData.append("files", item.file);
          }
        });
        if (!formData.has("files")) {
          formData.append("files", "");
        }
      }

      //视频
      if (checked.value == 2) {
        if (typeof videoFile.value == "string") {
          formData.append("files", "");
        } else {
          formData.append("files", videoFile.value);
          formData.append("delList", JSON.stringify([delfiles.value[0].id]));
        }
      }
      //文件
      if (checked.value == 3) {
        formData.append("files", txtFile.value);
      }

      formData.append("title", uptitle.value);
      formData.append("content", upmsg.value);
      formData.append("type", checked.value);
      formData.append("isOpen", openchecked.value);
      formData.append("caseId", route.query.id);
      formData.append("departmentType", result2.value);
      putcaseList(formData).then((res) => {
        uploadBool.value = false;
        if (res.data.code == 200) {
          showToast("修改案例成功");
          delfile.value = [];
          history.back();
        }
      });
    }
  }
  if (route.query.value == 3) {
    if (filevalue.value.length == 0 && checked.value == 1) {
      showToast("请上传图片");
    } else if (videoSrc.value == "" && checked.value == 2) {
      showToast("请上传视频");
    } else {
      uploadBool.value = true;
      let formData = new FormData();

      //图文
      if (checked.value == 1) {
        if (delfile.value.length > 0) {
          let arr: any = [];
          delfile.value.forEach((item) => {
            delfiles.value.forEach((item1) => {
              if (item.url == item1.url) {
                arr.push(item1.id);
              }
            });
          });

          formData.append("delList", arr.join(","));
        } else {
          formData.append("delList", []);
        }
        //
        filevalue.value.forEach((item) => {
          if ("file" in item) {
            formData.append("files", item.file);
          }
        });
        if (!formData.has("files")) {
          formData.append("files", "");
        }
      }

      //视频
      if (checked.value == 2) {
        // formData.append("files", videoFile.value);
        if (typeof videoFile.value == "string") {
          formData.append("files", "");
        } else {
          formData.append("files", videoFile.value);
          formData.append("delList", JSON.stringify([delfiles.value[0].id]));
        }
      }

      formData.append("title", uptitle.value);
      formData.append("content", upmsg.value);
      formData.append("type", checked.value);
      formData.append("isOpen", openchecked.value);
      formData.append("postBarId", route.query.id);
      formData.append("departmentType", result2.value);
      putforumList(formData).then((res) => {
        uploadBool.value = false;
        if (res.data.code == 200) {
          showToast("修改贴吧成功");
          delfile.value = [];
          history.back();
        }
      });
    }
  }
  //文献上传
  if (route.query.value == "wen") {
    if (txtFile.value == "") {
      showToast("请上传文献");
    } else if (cover.value.length == 0) {
      showToast("请上传文献封面");
    } else {
      uploadBool.value = true;
      let formData = new FormData();
      formData.append("title", uptitle.value);
      formData.append("content", upmsg.value);
      formData.append("departmentType", result2.value);
      formData.append("isOpen", openchecked.value);
      // formData.append("type", 1);
      formData.append("coverPictureFile", cover.value[0].file);
      formData.append("file", txtFile.value);
      addwenList(formData).then((res) => {
        uploadBool.value = false;
        if (res.data.code == 200) {
          showToast("上传文献成功");
          history.back();
        }
      });
    }
  }
};
const afterRead = (file: any) => {};
//删除文件
const beforeDeleteBack = (file: any, detail: any) => {
  filevalue.value.splice(detail.index, 1);
  delfile.value.push(file);
};
//视频
const getBigectURL = (event: any) => {
  if (event.target.files.length === 0) {
    // 用户取消了文件选择
    console.log("用户取消了文件选择");
    videoSrc.value = "";
    videoFile.value = "";
  } else {
    var current = event.target.files[0];
    var fileReader = new FileReader();
    fileReader.readAsDataURL(current);
    fileReader.onload = (e) => {
      videoSrc.value = e.currentTarget.result;
      videoFile.value = event.target.files[0];
    };
  }
};

//文本
const getFileURL = (event: any) => {
  if (event.target.files.length === 0) {
    // 用户取消了文件选择
    console.log("用户取消了文件选择");
    txtFile.value = "";
  } else {
    txtFile.value = event.target.files[0];
    console.log(txtFile.value);
  }
};
const onOversize = (file: any) => {
  showToast("文件大小不能超过 20 MB");
};
</script>

<style>
</style>