<template>
  <div>
    <van-nav-bar
      title="注册"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div>
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            v-model="username"
            name="用户名"
            label="用户名"
            placeholder="用户名"
            maxlength="8"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field name="radio" label="性别">
            <template #input>
              <van-radio-group v-model="checked" direction="horizontal">
                <van-radio name="0">男</van-radio>
                <van-radio name="1">女</van-radio>
                <van-radio name="2">其他</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-model="password"
            type="password"
            name="密码"
            label="密码"
            maxlength="20"
            minlength="5"
            placeholder="密码"
            :rules="[
              { required: true, message: '请填写密码' },
              { validator: minlen, message: '密码必须在6~20个字符之间' },
            ]"
          />
          <van-field
            v-model="repassword"
            type="password"
            name="密码"
            label="确认密码"
            maxlength="20"
            placeholder="确认密码"
            :rules="[
              { required: true, message: '请填写密码' },
              { validator, message: '密码不一致' },
            ]"
          />
          <van-field
            v-model="phone"
            name="手机"
            label="手机"
            type="number"
            maxlength="11"
            placeholder="手机号"
          />
          <van-field
            v-model="occupation"
            name="职业"
            label="职业"
            maxlength="10"
            placeholder="职业"
          />
        </van-cell-group>
        <div style="margin: 16px">
          <van-button round block type="primary" native-type="submit">
            注 册
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
    
<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import { showSuccessToast, showFailToast } from "vant";
import { addUser } from "@/api/user";
import { useRouter } from "vue-router";
const router = useRouter();
const onClickLeft = () => history.back();
const username = ref("");
const checked = ref("0");
const password = ref("");
const repassword = ref("");
const occupation = ref("");
const phone = ref("");
const onSubmit = (values) => {
  console.log("submit", values);
  let data = {
    username: values.用户名,
    password: values.密码,
    sex:values.radio,
    phonenumber:values.手机

  };
  addUser(data).then((res) => {
    if (res.data.code == 200) {
      showSuccessToast("注册成功");
      router.replace("/");
    }
  });
};
const validator = () => {
  return password.value == repassword.value;
};
const minlen = () => {
  return password.value.length >= 6 && password.value.length <= 20;
};
</script>

<style>
</style>