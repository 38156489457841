<template>
  <div class="scan-page">
    <!-- 返回导航栏 -->
    <van-nav-bar
      title="扫描二维码"
      left-text="取消"
      left-arrow
      fixed
      class="scan-index-bar"
      @click-left="clickIndexLeft()"
    ></van-nav-bar>
    <!-- 扫码区域 -->
    <video ref="video" id="video" class="scan-video" autoplay></video>
    <!-- 提示语 -->
    <div v-show="tipShow" class="scan-tip">{{ tipMsg }}</div>
  </div>
</template>
 
<script setup lang="ts">
import { BrowserMultiFormatReader } from "@zxing/library";
import { Toast, Dialog, Notify } from "vant";
import { videoRoom } from "@/api/video";
import { onMounted, onUnmounted, ref, watch } from "vue";
import { showConfirmDialog, showToast } from "vant";

import { videoRoomList, startLive, endLive } from "@/api/video";
import { useRouter } from "vue-router";
const router = useRouter();

const props = defineProps(["ids"]);
const emit = defineEmits(["sacExit"]);

const codeReader = ref(null); // 是否展示提示
const tipShow = ref(false); // 提示文本内容
const tipMsg = ref(""); // 扫码结果文本内容
const timeText = ref(""); // 扫码结果文本内容
onMounted(() => {
  openScan();
});

// watch: {
//   $route(to, from) {
//     if (to.path == "/ScanCodePage") {
//       // 当处于该页面时
//       this.openScan();
//     }
//   },
// },

onUnmounted(() => {
  codeReader.value.reset();
  codeReader.value = null;
});

const openScan = async () => {
  // 初始化摄像头
  codeReader.value = await new BrowserMultiFormatReader();

  codeReader.value
    .getVideoInputDevices()
    .then((videoDevices: any) => {
      tipMsg.value = "正在调用摄像头...";
      tipShow.value = true;
      console.log("get-videoDevices", videoDevices);

      // 默认获取摄像头列表里的最后一个设备id，通过几部测试机发现一般前置摄像头位于列表里的前面几位，所以一般获取最后一个的是后置摄像头
      let firstDeviceId = videoDevices[videoDevices.length - 1].deviceId;
      // 一般获取了手机的摄像头列表里不止一个，有的手机摄像头高级多层，会有变焦摄像头等情况，需要做处理
      if (videoDevices.length > 1) {
        // 一般通过判断摄像头列表项里的 label 字段，'camera2 0, facing back' 字符串含有 'back' 和 '0'，大部分机型是这样，如果有些机型没有，那就还是默认获取最后一个
        firstDeviceId = videoDevices.find((el: any) => {
          return el.label.indexOf("back") > -1 && el.label.indexOf("0") > -1;
        })
          ? videoDevices.find((el: any) => {
              return (
                el.label.indexOf("back") > -1 && el.label.indexOf("0") > -1
              );
            }).deviceId
          : videoDevices[videoDevices.length - 1].deviceId;
      }
      console.log("get-firstDeviceId", firstDeviceId);

      decodeFromInputVideoFunc(firstDeviceId);
    })
    .catch((err) => {
      tipShow.value = false;
      console.error(err);
    });
};
const decodeFromInputVideoFunc = (firstDeviceId: any) => {
  // 使用摄像头扫描
  codeReader.value.reset(); // 重置
  codeReader.value.decodeFromInputVideoDeviceContinuously(
    firstDeviceId,
    "video",
    (result: any, err: any) => {
      tipMsg.value = "正在尝试识别...";
      if (result) {
        if (result.text.includes("key")) {
          tipShow.value = false;
          let str = JSON.stringify(result.text);
          // 创建一个正则表达式，匹配 "key:" 后面的所有字符
          const keyID = "key";
          const regex = new RegExp(`${keyID}:\\s*(.*)`);
          const match = result.text.match(regex);
          let content = null;

          if (match && match[1]) {
            content = match[1].trim(); // 去除前后空格
          } else {
            content = null; // 如果没有找到匹配项，返回 null
          }

          showConfirmDialog({
            title: "提示",
            message: content,
          })
            .then(() => {
              let data = {
                id: props.ids,
                clientId: content,
                // sn: scanText.value,
                times: timeText.value,
              };
              // 发送参数
              videoRoom(data).then((res) => {
                console.log(res);
                if (res.data.code == 200) {
                  //开播
                  let data1 = {
                    id: props.ids,
                    status: "1",
                  };
                  startLive(data1).then((res1) => {
                    console.log(res1);
                    if (res1.data.code == 200) {
                      showToast("开播成功");
                      sessionStorage.setItem("videoStatus", 0);
                      //点击确认
                      emit("sacExit", false);
                      router.push({
                        path: "/VideoIndex",
                      });
                    }
                  });
                }
              });
            })
            .catch(() => {
              // on cancel
            });
        } else {
          showConfirmDialog({
            title: "提示",
            message: "未知二维码",
          })
            .then(() => {})
            .catch(() => {
              // on cancel
            });
        }
      }
    }
  );
};
const clickIndexLeft = () => {
  emit("sacExit", false);
};
</script>
 
<style lang="scss">
.scan-index-bar {
  background-image: linear-gradient(-45deg, #42a5ff, #59cfff);
  .van-nav-bar__title,
  .van-nav-bar__arrow,
  .van-nav-bar__text {
    color: #fff !important;
  }
}
.scan-page {
  min-height: 100vh;
  background-color: #363636;
  overflow-y: hidden;
  .scan-video {
    height: 80vh;
  }
  .scan-tip {
    width: 100vw;
    text-align: center;
    color: white;
    font-size: 5vw;
  }
}
</style>
