import request from "@/utils/request";

// 登录
export function loginUser(data: any) {
    return request({
        url: '/auth/login',
        method: 'post',
        data: data,
    })
}
// 退出登录
export function delUser() {
    return request({
        url: '/auth/logout',
        method: 'delete',
        // data: ,
    })
}
// 退出微信登录
export function delWxUser() {
    return request({
        url: ' /auth/wx/logout',
        method: 'delete',
        // data: ,
    })
}
// 注册
export function addUser(data: any) {
    return request({
        url: '/auth/register',
        method: 'post',
        data: data,
    })
}

// 用户信息
export function getUserMsg(data: any) {
    return request({
        url: `/system/wxUser/getInfo`,
        method: 'get',
        // params: data,
    })
}
// 刷新token
export function updateToken(data: any) {
    return request({
        url: `/auth/refresh`,
        method: 'get',
        // params: data,
    })
}
// 用户列表
export function getList(data: any) {
    return request({
        url: `/user/list`,
        method: 'get',
        params: data,
    })
}
// 修改用户信息
export function editUser(data: any) {
    return request({
        url: `/system/wxUser`,
        method: 'put',
        data: data,
    })
}
//浏览历史
export function HisList(data: any) {
    return request({
        url: `/bbs/browsingHistory/list`,
        method: 'get',
        params: data,
    })
}
//保存用户信息
export function saveUserMsg(data: any) {
    return request({
        url: "/system/wxUser/addUserInfo",
        method: 'post',
        data: data
    })
}

export function CollList(data: any) {
    return request({
        url: `/bbs/browsingHistory/list`,
        method: 'get',
        params: data,
    })
}

//我的消息 
export function messageList(data: any) {
    return request({
        url: `/bbs/message/list`,
        method: 'get',
        params: data,
    })
}

//微信授权登录
export function loginWxUser(data: any) {
    return request({
        url: '/auth/wx',
        method: 'post',
        data: data,
    })
}
//获取用户信息
export function getWxUserMsg(data: any) {
    return request({
        url: '/auth/wx/login',
        method: 'get',
        params: data
    })
}