<template>
  <div style="width: 100%; height: 70vh">
    <van-pull-refresh
      v-model="loading"
      @refresh="onRefresh"
      :disabled="freshBool"
    >
      <div class="msgbox" @scroll="handleScroll" v-loading="loadBool">
        <van-card
          :title="
            item.businessType == 1
              ? item.bbsCase.title
              : item.businessType == 2
              ? item.bbsPostBar.title
              : item.bbsLiterature.title
          "
          @click="nextToMsg(item.businessId, item.businessType - 1)"
          v-for="(item, index) in HisData"
          :key="index"
        >
          <template #thumb>
            <img :src="imgUrl[index]" style="width: 40vw; height: 100%" />
          </template>
          <template #tags>
            <!-- 最多显示三行 -->
            <!-- <div class="van-multi-ellipsis--l3" style="margin-bottom: 6px">
              {{
                item.businessType == 1
                  ? item.bbsCase.content
                  : item.businessType == 2
                  ? item.bbsPostBar.content
                  : item.bbsLiterature.content
              }}
            </div> -->

            <div style="padding-top: 10px">
              <span
                style="
                  margin-right: 5px;
                  font-size: 12px;
                  color: rgb(152, 152, 152);
                "
                v-if="item.businessType == 1"
                >案例</span
              >
              <span
                style="
                  margin-right: 5px;
                  font-size: 12px;
                  color: rgb(152, 152, 152);
                "
                v-if="item.businessType == 2"
                >贴吧</span
              >
              <span
                style="
                  margin-right: 5px;
                  font-size: 12px;
                  color: rgb(152, 152, 152);
                "
                v-if="item.businessType == 3"
                >文献</span
              >
              <span
                style="
                  margin-right: 5px;
                  font-size: 12px;
                  color: rgb(143, 195, 32);
                "
                v-if="item.bbsCase.type == 1"
                >图文</span
              >
              <span
                style="
                  margin-right: 5px;
                  font-size: 12px;
                  color: rgb(143, 195, 32);
                "
                v-if="item.bbsCase.type == 2"
                >视频</span
              >
              <span
                style="
                  margin-right: 5px;
                  font-size: 12px;
                  color: rgb(143, 195, 32);
                "
                v-if="item.bbsCase.type == 3"
                >文本</span
              >
              <span
                style="
                  margin-right: 5px;
                  font-size: 12px;
                  color: rgb(143, 195, 32);
                "
                v-if="item.bbsPostBar.type == 1"
                >图文</span
              >
              <span
                style="
                  margin-right: 5px;
                  font-size: 12px;
                  color: rgb(143, 195, 32);
                "
                v-if="item.bbsPostBar.type == 2"
                >视频</span
              >
              <span
                style="
                  margin-right: 5px;
                  font-size: 12px;
                  color: rgb(143, 195, 32);
                "
                v-if="item.bbsPostBar.type == 3"
                >文本</span
              >
            </div>
            <div style="padding-top: 10px">
              {{ item.operTime }}
            </div>
          </template>
          <!-- <template #footer>
            
          </template> -->
        </van-card>
      </div>
    </van-pull-refresh>
  </div>
</template>
  
  <script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import { HisList } from "@/api/user";
import { useRouter } from "vue-router";
import { showToast } from "vant";
const router = useRouter();
const HisData = ref([]);
const loading = ref(false);
const loadBool = ref(false);

const freshBool = ref(false);
const imgUrl = ref([]);
onMounted(() => {
  getHisList();
});
//列表
const getHisList = () => {
  imgUrl.value = [];
  loadBool.value = true
  let data = {
    pageNum: 1,
    pageSize: 100,
  };
  HisList(data).then((res) => {
    if (res.status == 200) {
      imgUrl.value = [];
      // item.bbsCase.title : item.bbsPostBar.title
      HisData.value = res.data.data;
      HisData.value.forEach((item, index) => {
        if (
          item.bbsCase == null &&
          item.bbsLiterature == null &&
          item.bbsPostBar !== null
        ) {
          HisData.value[index].bbsCase = { filesList: [""] };
          HisData.value[index].bbsLiterature = { filesList: [""] };

          if (item.bbsPostBar.type == 2) {
            imgUrl.value.push(item.bbsPostBar.videoTitle);
          }
          if (item.bbsPostBar.type == 1) {
            if (item.bbsPostBar.filesList.length == 0) {
              imgUrl.value.push("");
            } else {
              imgUrl.value.push(item.bbsPostBar.filesList[0].url);
            }
          }
        }
        if (
          item.bbsPostBar == null &&
          item.bbsLiterature == null &&
          item.bbsCase != null
        ) {
          HisData.value[index].bbsPostBar = { filesList: [""] };
          HisData.value[index].bbsLiterature = { filesList: [""] };
          if (item.bbsCase.type == 2) {
            imgUrl.value.push(item.bbsCase.videoTitle);
          }
          if (item.bbsCase.type == 1) {
            // imgUrl.value.push(item.bbsCase.filesList[0].url);
            if (item.bbsPostBar.filesList.length == 0) {
              imgUrl.value.push("");
            } else {
              imgUrl.value.push(item.bbsCase.filesList[0].url);
            }
          }
          if (item.bbsCase.type == 3) {
            imgUrl.value.push(require("@/assets/文件.png"));
          }
        }
        if (
          item.bbsPostBar == null &&
          item.bbsCase == null &&
          item.bbsLiterature != null
        ) {
          HisData.value[index].bbsPostBar = { filesList: [""] };
          HisData.value[index].bbsCase = { filesList: [""] };
          imgUrl.value.push(item.bbsLiterature.literatureCoverPictureUrl);
        }
      });
      setTimeout(()=>{
        loadBool.value = false
      },1000)
    }
  });
};
const nextToMsg = (value: any, index: any) => {
  router.push({
    path: "/TiebMsg",
    query: {
      value: value,
      index: index,
    },
  });
};
//滚动条触顶
const handleScroll = (event) => {
  const scrollTop = event.target.scrollTop;
  if (scrollTop == 0) {
    freshBool.value = false;
  } else {
    freshBool.value = true;
  }
};
//刷新
const onRefresh = () => {
  let data = {
    pageNum: 1,
    pageSize: 100,
  };
  HisList(data)
    .then((res) => {
      if (res.status == 200) {
        imgUrl.value = [];
        HisData.value = res.data.data;
        HisData.value.forEach((item, index) => {
          if (
            item.bbsCase == null &&
            item.bbsLiterature == null &&
            item.bbsPostBar !== null
          ) {
            HisData.value[index].bbsCase = { filesList: [""] };
            HisData.value[index].bbsLiterature = { filesList: [""] };
            if (item.bbsPostBar.type == 2) {
              imgUrl.value.push(item.bbsPostBar.videoTitle);
            }
            if (item.bbsPostBar.type == 1) {
              if (item.bbsPostBar.filesList.length == 0) {
                imgUrl.value.push("");
              } else {
                imgUrl.value.push(item.bbsPostBar.filesList[0].url);
              }
            }
          }
          if (
            item.bbsPostBar == null &&
            item.bbsLiterature == null &&
            item.bbsCase != null
          ) {
            HisData.value[index].bbsPostBar = { filesList: [""] };
            HisData.value[index].bbsLiterature = { filesList: [""] };
            if (item.bbsCase.type == 2) {
              imgUrl.value.push(item.bbsCase.videoTitle);
            }
            if (item.bbsCase.type == 1) {
              imgUrl.value.push(item.bbsCase.filesList[0].url);
            }
            if (item.bbsCase.type == 3) {
              imgUrl.value.push(require("@/assets/文件.png"));
            }
          }
          if (
            item.bbsPostBar == null &&
            item.bbsCase == null &&
            item.bbsLiterature != null
          ) {
            HisData.value[index].bbsPostBar = { filesList: [""] };
            HisData.value[index].bbsCase = { filesList: [""] };
            imgUrl.value.push(item.bbsLiterature.literatureCoverPictureUrl);
          }
        });
        showToast("刷新成功");
        loading.value = false;
      }
    })
    .catch((err) => {
      showToast("刷新失败");
      loading.value = false;
    });
};
</script>
  
  
  <style scoped>
.msgbox {
  width: 96%;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  margin: 5px auto 0 auto;
  padding: 5px;
}
::v-deep .van-card__content > div {
  padding-left: calc(100% - 45vw);
}
</style>