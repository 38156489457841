<template>
  <div>
    <van-nav-bar
      title="重置密码"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div>
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            v-model="username"
            name="用户名"
            label="用户名"
            placeholder="用户名"
            maxlength="10"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
        </van-cell-group>
        <div style="margin: 16px">
          <van-button round block type="primary" native-type="submit">
            重置密码
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
      
  <script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import { editUser } from "@/api/user";
const onClickLeft = () => history.back();
const username = ref("");
const password = ref("");
const onSubmit = (values) => {
  console.log("submit", values);
  let data={
    username:username.value,
    password:'123456'
  }
  editUser(data).then((res) => {
    console.log(res);
  });
};
</script>
  
  <style>
</style>