import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/Login.vue'
import ForumIndex from '../views/ForumIndex.vue'
import MainIndex from '../views/MainIndex.vue'
import VideoIndex from '../views/VideoIndex.vue'
import UserIndex from '../views/UserIndex.vue'
import Setting from '../views/Setting.vue'
import CreateUser from '../views/CreateUser.vue'
import ResetUser from '../views/ResetUser.vue'
import TiebMsg from '../views/TiebMsg.vue'
import ReleaseIndex from '../views/ReleaseIndex.vue'
import MyRelease from '../views/MyRelease.vue'
import OtherModule from '../views/OtherModule.vue'
import ReleaseVideo from '../views/ReleaseVideo.vue'
import VideoMsg from '../views/VideoMsg.vue'

import { loginWxUser } from "@/api/user";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: Login
  },

  {
    path: '/homeView',
    name: 'homeView',
    component: HomeView
  },
  {
    path: '/ForumIndex',
    name: 'ForumIndex',
    component: ForumIndex
  },
  {
    path: '/MainIndex',
    name: 'MainIndex',
    component: MainIndex
  },
  {
    path: '/VideoIndex',
    name: 'VideoIndex',
    component: VideoIndex
  },
  {
    path: '/UserIndex',
    name: 'UserIndex',
    component: UserIndex
  },
  {
    path: '/Setting/:index',
    name: 'Setting',
    component: Setting
  },
  {
    path: '/CreateUser',
    name: 'CreateUser',
    component: CreateUser
  },
  {
    path: '/ResetUser',
    name: 'ResetUser',
    component: ResetUser
  },
  {
    path: '/TiebMsg',
    name: 'TiebMsg',
    component: TiebMsg
  },
  {
    path: '/ReleaseIndex',
    name: 'ReleaseIndex',
    component: ReleaseIndex
  },
  {
    path: '/MyRelease',
    name: 'MyRelease',
    component: MyRelease
  },
  {
    path: '/OtherModule',
    name: 'OtherModule',
    component: OtherModule
  },
  {
    path: '/ReleaseVideo',
    name: 'ReleaseVideo',
    component: ReleaseVideo
  },
  {
    path: '/VideoMsg',
    name: 'VideoMsg',
    component: VideoMsg
  },
]



const router = createRouter({
  history: createWebHistory(''),
  // history: createWebHistory('/nebula'),
  routes
})

router.beforeEach((to, from, next) => {

  const isWeixinBrowser = /micromessenger/i.test(navigator.userAgent)
  if (isWeixinBrowser) {
    console.log('当前浏览器是微信浏览器')
    next()
  } else {
    console.log('当前浏览器不是微信浏览器')
    loginWxUser({}).then((res) => {
      console.log(res);
      window.location.href = res.data.msg;
    });
  }

  // const token = localStorage.getItem('token')
  // if (token) {
  //   if (to.path == '/') {
  //     next("/MainIndex")
  //   } else {
  //     next();
  //   }

  // } else {
  //   if (to.path == '/' || to.path == '/CreateUser' || to.path == '/ResetUser') {
  //     next();
  //   } else {
  //     next('/');
  //   }
  // }
})
export default router
