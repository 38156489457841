import axios from 'axios'
import { showNotify } from 'vant';

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL


if (process.env.VUE_APP_MODE === 'development') {
    //开发环境下的执行操作
} else if (process.env.VUE_APP_MODE === 'test') {
    //测试环境下的执行操作
} else {
    //生产环境下的执行操作
}
// 创建 axios 对象
const instance = axios.create({
    baseURL: 'https://nebula.optomedic.com/nebula-api/', //测试
    // baseURL: 'http://192.168.96.126:8080/', //本地
    timeout: 1000 * 15 // 网络延时
})

// 添加请求拦截器 => 前端给后端的参数【还没到后端响应】
instance.interceptors.request.use(function (config) {
    // 在发送请求之前做：token
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器 => 后端给前端的数据【后端返回给前端的东西】
instance.interceptors.response.use(function (res) {
    // 对响应数据做点什么
    const code = res.data.code
    if (code == 500) {
        showNotify({ type: 'warning', message: res.data.msg, duration: 1000 });
    }
    if (code == 404) {
        showNotify({ type: 'warning', message: res.data.msg, duration: 1000 });
    }
    if (code == 401) {
        alert('登录信息已过期，请重新登录')
        localStorage.removeItem('token')
        window.location.href = '/'
        // localStorage.removeItem("token");
        // showNotify({ type: 'warning', message: '登录信息已过期，请重新登录', duration: 1000 });
    }
    if (code == 200) {
        const timestamp = Date.now().toString();
        localStorage.setItem("time", timestamp);
    }
    if (code == 413) {
        showNotify({ type: 'warning', message: res.data.msg, duration: 1000 });
    }
    if (code == 504) {
        showNotify({ type: 'warning', message: res.data.msg, duration: 1000 });
    }
    return res;
}, function (error) {
    // 对响应错误做点什么
    showNotify({ type: 'warning', message: '未知信息错误', duration: 1000 });
    return Promise.reject(error);
});

// 最终返回的对象
export default instance

