<template>
  <div>
    <van-tabbar
      :model-value="props.active"
      @change="onChange"
      active-color="rgb(130,200,160)"
      inactive-color="rgb(151,154,160)"
    >
      <van-tabbar-item icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item icon="video-o">直播</van-tabbar-item>
      <van-tabbar-item icon="smile-comment-o">论坛</van-tabbar-item>
      <van-tabbar-item icon="description-o">作品</van-tabbar-item>
      <van-tabbar-item icon="friends-o">我的</van-tabbar-item>
      <!-- friends-o -->
    </van-tabbar>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
// const active = ref(0);
const props = defineProps(["active"]);
const router = useRouter();
const onChange = (e:any) => {
  console.log(e);

  if (e == 0) {
    router.push("/MainIndex");
  }
  if (e == 1) {
    router.push("/VideoIndex");
  }
  if (e == 2) {
    router.push("/ForumIndex");
  }
  if (e == 3) {
    router.push("/MyRelease");
  }
  if (e == 4) {
    router.push("/UserIndex");
  }
};
</script>
