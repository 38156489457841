import request from "@/utils/request";

//贴吧列表
export function forumList(data: any) {
    return request({
        url: '/bbs/postBar/list',
        method: 'get',
        params: data
    })
}
export function addforumList(data: any) {
    return request({
        url: '/bbs/postBar',
        method: 'post',
        data: data
    })
}
export function putforumList(data: any) {
    return request({
        url: '/bbs/postBar',
        method: 'put',
        data: data
    })
}
export function delforumList(data: any) {
    return request({
        url: '/bbs/postBar/' + data,
        method: 'delete',
    })
}
//案例列表
export function caseList(data: any) {
    return request({
        url: '/bbs/case/list',
        method: 'get',
        params: data
    })
}
export function addcaseList(data: any) {
    return request({
        url: '/bbs/case',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
export function putcaseList(data: any) {
    return request({
        url: '/bbs/case',
        method: 'put',
        data: data
    })
}
export function delcaseList(data: any) {
    return request({
        url: '/bbs/case/' + data,
        method: 'delete',
    })
}
//贴吧详情
export function Msgdata1(data: any) {
    return request({
        url: '/bbs/postBar/' + data,
        method: 'get',
        // params: data
    })
}
//案例详情
export function Msgdata2(data: any) {
    return request({
        url: '/bbs/case/' + data,
        method: 'get',
        // params: data
    })
}

//点赞收藏浏览 
export function collLikeApi(data: any) {
    return request({
        url: '/bbs/browsingHistory/addHistoryInfo',
        method: 'post',
        data: data
    })
}
//取消点赞收藏浏览 
export function cancelCollLikeApi(data: any) {
    return request({
        url: '/bbs/browsingHistory/cancel',
        method: 'post',
        data: data
    })
}
//案例评论
export function commentApi(data: any) {
    return request({
        url: '/bbs/caseReview/list',
        method: 'get',
        params: data
    })
}
//发布评论
export function addcommentApi(data: any) {
    return request({
        url: '/bbs/caseReview',
        method: 'post',
        data: data
    })
}
//贴吧发布评论
export function TbcommentApi(data: any) {
    return request({
        url: '/bbs/postBarReview/list',
        method: 'get',
        params: data
    })
}
//贴吧发布评论
export function TbaddcommentApi(data: any) {
    return request({
        url: '/bbs/postBarReview',
        method: 'post',
        data: data
    })
}

// 文献
export function getwenList(data: any) {
    return request({
        url: "/bbs/literature/list",
        method: 'get',
        params: data
    })
}
export function addwenList(data: any) {
    return request({
        url: "/bbs/literature",
        method: 'post',
        data: data
    })
}
//文献详情
export function getwenMag(data: any) {
    return request({
        url: "/bbs/literature/" + data,
        method: 'get',
    })
}
//文献评论列表
export function getwenReviewMag(data: any) {
    return request({
        url: "/bbs/literatureReview/list",
        method: 'get',
        params: data
    })
}
//文献发布评论
export function addwenReviewMag(data: any) {
    return request({
        url: "/bbs/literatureReview",
        method: 'post',
        data: data
    })
}