<template>
  <div>
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
          v-model="username"
          name="用户名"
          label="用户名"
          placeholder="用户名"
          :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field name="radio" label="性别">
          <template #input>
            <van-radio-group v-model="sex" direction="horizontal">
              <van-radio name="0">男</van-radio>
              <van-radio name="1">女</van-radio>
              <van-radio name="2">其他</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="phonenumber"
          name="手机"
          label="手机"
          placeholder="手机号"
          :rules="[{ required: true, message: '请填写用户名' }]"
          type="number"
          maxlength="11"
        />
        <!-- <van-field
          v-model="occupation"
          name="职业"
          label="职业"
          maxlength="10"
          placeholder="职业"
        /> -->
        <van-field
          readonly
          v-model="createTime"
          name="注册时间"
          label="注册时间"
        />
      </van-cell-group>
      <div style="margin: 16px">
        <van-button
          round
          block
          type="primary"
          native-type="submit"
          color="rgb(176,217,157)"
        >
          修改
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script setup lang="ts">
import { showNotify } from "vant";
import { onMounted, onUnmounted, ref } from "vue";
import { getUserMsg, editUser, updateToken, getList } from "@/api/user";
import { useRouter } from "vue-router";
const router = useRouter();
const username = ref("");
const password = ref("");
const oldpassword = ref("");
const newpassword = ref("");
const createTime = ref("");
const phonenumber = ref("");
const sex = ref("");
const wxUserId = ref("");
const occupation = ref("");
const onSubmit = (values: any) => {
  console.log("submit", values);
  let data = {
    nickName: values.用户名,
    phonenumber: values.手机,
    sex: Number(values.radio),
    wxUserId: wxUserId.value,
  };
  editUser(data).then((res) => {
    console.log(res);
    if (res.data.code == 200) {
      router.push("/UserIndex");
      localStorage.setItem("username", values.用户名);
      showNotify({ type: "success", message: "修改成功", duration: 1000 });
    }
  });
};
const validator = () => {
  return password.value == newpassword.value;
};
onMounted(() => {
  localStorage.getItem("username");
  let data = {
    username: localStorage.getItem("username"),
  };
  getUserMsg(data).then((res) => {
    console.log(res);
    if (res.data.code == 200) {
      username.value = res.data.data.nickName;
      createTime.value = res.data.data.createTime;
      phonenumber.value = res.data.data.phonenumber;
      sex.value = JSON.stringify(res.data.data.sex);
      wxUserId.value = res.data.data.wxUserId;
    }
  });
});
</script>


<style>
</style>