<template>
  <div style="width: 100%; height: 70vh">
    <van-pull-refresh
      v-model="loading"
      @refresh="onRefresh"
      :disabled="freshBool"
    >
      <div class="msgbox" @scroll="handleScroll">
        <van-card
          :title="'回复人：' + item.reviewName"
          @click="nextToMsg(item.businessId, item.businessType - 1)"
          v-for="(item, index) in msgData"
          :key="index"
        >
          <template #tags>
            <!-- 最多显示三行 -->
            <div class="van-multi-ellipsis--l3" style="margin-bottom: 6px">
              回复内容：{{ item.content }}
            </div>
            <van-tag
              style="margin-right: 6px"
              plain
              type="success"
              v-if="item.businessType == 1"
              >案例</van-tag
            >
            <van-tag
              style="margin-right: 6px"
              plain
              type="warning"
              v-if="item.businessType == 2"
              >贴吧</van-tag
            >
            <div style="padding-top: 6px; text-align: right">
              {{ item.reviewTime }}
            </div>
          </template>
          <!-- <template #footer>
            
          </template> -->
        </van-card>
      </div>
    </van-pull-refresh>
  </div>
</template>
  
  <script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import { HisList, messageList } from "@/api/user";
import { useRouter } from "vue-router";
import { showToast } from "vant";
const router = useRouter();
const msgData = ref([]);
const loading = ref(false);
const freshBool = ref(false);
onMounted(() => {
  getHisList();
});
//列表
const getHisList = () => {
  let data = {
    pageNum: 1,
    pageSize: 100,
  };
  // HisList
  messageList(data).then((res) => {
    console.log("msg", res);
    if (res.status == 200) {
      // item.bbsCase.title : item.bbsPostBar.title
      msgData.value = res.data.data;
      msgData.value.forEach((item, index) => {
        if (msgData.value[index].content.includes("：")) {
          msgData.value[index].content =
            msgData.value[index].content.split("：")[1];
        }
      });
    }
  });
};
const nextToMsg = (value: any, index: any) => {
  router.push({
    path: "/TiebMsg",
    query: {
      value: value,
      index: index,
    },
  });
};
//滚动条触顶
const handleScroll = (event: any) => {
  const scrollTop = event.target.scrollTop;
  if (scrollTop == 0) {
    freshBool.value = false;
  } else {
    freshBool.value = true;
  }
};
//刷新
const onRefresh = () => {
  let data = {
    pageNum: 1,
    pageSize: 100,
  };
  messageList(data)
    .then((res) => {
      if (res.status == 200) {
        msgData.value = res.data.data;
        msgData.value.forEach((item, index) => {
          if (msgData.value[index].content.includes("：")) {
            msgData.value[index].content =
              msgData.value[index].content.split("：")[1];
          }
        });
        // msgData.value.forEach((item, index) => {
        //   if (item.bbsCase == null) {
        //     msgData.value[index].bbsCase = { filesList: [""] };
        //   }
        //   if (item.bbsPostBar == null) {
        //     msgData.value[index].bbsPostBar = { filesList: [""] };
        //   }
        // });
        showToast("刷新成功");
        loading.value = false;
      }
    })
    .catch((err) => {
      showToast("刷新失败");
      loading.value = false;
    });
};
</script>
  
  
  <style scoped>
.msgbox {
  width: 100%;
  height: calc(100vh - 50px);
  overflow-y: scroll;
}

</style>