<template>
  <div>
    <van-nav-bar
      title="未定义"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <div>暂未开发</div>
  </div>
</template>
<script setup lang="ts">
const onClickLeft = () => history.back();
</script>