<!-- 直播间 -->
<template>
  <div>
    <div style="width: 100%">
      <div
        style="
          width: 100%;
          height: 46px;
          background: linear-gradient(
            to right,
            rgb(223, 236, 156),
            rgb(130, 198, 159)
          );
        "
      >
        <div style="display: flex; align-items: center; padding-top: 6px">
          <van-icon
            name="arrow-left"
            size="18"
            style="padding: 0 8px 0 12px; color: white"
            @click="onClickLeft"
          />
          <div style="display: flex; align-items: center">
            <img
              src="@/assets/profile.jpg"
              style="width: 34px; height: 34px; border-radius: 50%"
            />
            <span style="padding-left: 10px; color: white">{{
              parentMsg.createBy
            }}</span>
          </div>
        </div>
      </div>
      <div class="msgBox">
        <div
          style="margin-bottom: 10px; height: 39vh"
          v-if="parentMsg.status == 1"
        >
          <!-- -webkit-filter:brightness(1) contrast(1) saturate(0.8) hue-rotate(352deg) -->
          <video
            ref="videoPlayerRef"
            class="video-js vjs-default-skin"
            style="
              width: 100%;
              height: 100%;
              -webkit-filter: brightness(1) contrast(0.83) saturate(0.95)
                hue-rotate(357deg);
            "
            autoplay
            controls
            id="myVideo"
            @fullscreenchange="handleFullscreenChange"
          />
        </div>
        <div
          style="
            margin-bottom: 10px;
            height: auto;
            background-color: black;
            display: flex;
            align-items: center;
          "
          v-else
        >
          <img :src="parentMsg.coverUrl" alt="直播封面" style="width: 100%" />
        </div>

        <p style="padding: 0px 10px 5px 10px; font-size: 18px">
          {{ parentMsg.roomName }}
        </p>

        <van-tabs v-model:active="active">
          <van-tab title="聊天">
            <div
              style="
                width: 100%;
                height: calc(100vh - (50vh));
                background-color: rgb(242, 242, 242);
                overflow-y: scroll;
              "
            >
              <div v-for="(item, index) in msgList" :key="index">
                <div style="padding: 5px 10px; font-size: 14px">
                  <span>{{ item.reviewName }}：</span>
                  <span class="clmsg">{{ item.content }}</span>
                </div>
              </div>
            </div>
          </van-tab>
          <van-tab title="详情">暂无</van-tab>
        </van-tabs>
        <van-tabbar v-show="active == 0">
          <van-field
            v-model="plvalue"
            clearable
            label="评论："
            left-icon="comment"
            :placeholder="placemsg"
            autocomplete="off"
            :disabled="parentMsg.status == 2"
          >
            <template #button>
              <van-button
                v-if="textBool"
                size="small"
                type="primary"
                @click="childTomsg"
                >发送</van-button
              >
            </template></van-field
          >
        </van-tabbar>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { showToast } from "vant";
import { useRoute } from "vue-router";
import { nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import {
  commentApi,
  addcommentApi,
  TbcommentApi,
  TbaddcommentApi,
  collLikeApi,
  cancelCollLikeApi,
} from "@/api/forum";
import { addReviewList, getReviewList } from "@/api/video";
//
import { reactive } from "vue";
import {
  UToast,
  CommentApi,
  CommentSubmitParam,
  ConfigApi,
  str,
} from "undraw-ui";
import emoji from "./emoji";

import FlvPlayer from "flv.js";
import videojs from "video.js";
import "video.js/dist/video-js.css";

//
let route = useRoute();
console.log("msg", JSON.parse(route.query.value)); //query传参
let parentMsg = JSON.parse(route.query.value);
const plvalue = ref("");
const textBool = ref(false);
const inputBool = ref(false);
const rePlarr = ref([]);
const msgList = ref([]);
const total = ref(0);
const active = ref(0);

const onClickLeft = () => history.back();
watch(plvalue, (newValue, oldValue) => {
  if (newValue == "") {
    textBool.value = false;
  } else {
    textBool.value = true;
  }
});
const forumData = ref([]);

const videoPlayerRef = ref();
const myPlayer = ref();
const jumpTo = ref(null);
const imgUrl = ref(null);

onMounted(() => {
  imgUrl.value = localStorage.getItem("userImage");
  if (parentMsg.status == 1) {
    if (isIphone()) {
      startVideo();
    } else {
      startVideo1();
    }
  }
  //加载评论
  getreList();
});

onUnmounted(() => {
  if (myPlayer.value) {
    if (parentMsg.status == 1) {
      if (isIphone()) {
        myPlayer.value.dispose();
      } else {
        myPlayer.value.pause();
        myPlayer.value.unload();
        myPlayer.value.detachMediaElement();
        myPlayer.value.destroy();
        myPlayer.value = null;
        clearInterval(jumpTo.value);
      }
    }
  }
  // let web = new WebSocket()
});

const handleFullscreenChange = () => {
  // var video = document.getElementById("myVideo");
  // video.style.transform = "rotate(0deg)";
};

const copyBtn = (val: Number) => {
  // navigator.clipboard.writeText 该方法需要在安全域下才能够使用，比如：https 协议的地址、127.0.0.1、localhost
  if (val == 1) {
    navigator.clipboard.writeText(parentMsg.liveUrl);
  } else {
    navigator.clipboard
      .writeText(parentMsg.livePushUrl)
      .then(() => {
        // this.$message.success("复制成功");
      })
      .catch((err) => {
        // 复制失败
        console.error("复制失败");
      });
  }
};
const getreList = () => {
  let data = {
    roomId: parentMsg.roomId,
    pageNum: 1,
    pageSize: 1000,
  };
  getReviewList(data).then((res) => {
    if (res.data.code == 200) {
      msgList.value = res.data.rows;
    }
  });
};

const isIphone = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /iPhone/i.test(userAgent);
};

//初始化播放 m3u8
const startVideo = () => {
  nextTick(() => {
    myPlayer.value = videojs(videoPlayerRef.value, {
      // poster: "//vjs.zencdn.net/v/oceans.png",//视频封面照片
      controls: true, //视频控件
      autoplay: true, //自动播放
      sources: [
        {
          src: parentMsg.liveUrlM3u8, //播放视频地址
          // src: parentMsg.liveUrl, //播放视频地址
          type: "application/x-mpegURL", //播放m3u8需要设置的格式
        },
      ],
      // // 配置支持播放flv格式视频流
      controlBar: {
        remainingTimeDisplay: {
          displayNegative: false,
        },
      },
      // playbackRates: [0.5, 1, 1.5, 2], //设置播放速度
    });
  });
};

//flv
const startVideo1 = async () => {
  FlvPlayer.isSupported() && (await FlvPlayer.getFeatureList());

  myPlayer.value = FlvPlayer.createPlayer({
    type: "flv",
    url: parentMsg.liveUrl,
    isLive: true, //表示直播流
    hasVideo: true, //表示播放器需要支持视频播放
    // hasAudio: true,
  });

  // 设置视频元素
  myPlayer.value.attachMediaElement(videoPlayerRef.value);

  // 监听播放器就绪事件
  myPlayer.value.on(FlvPlayer.Events.MEDIA_INFO, () => {
    // 在此可以做一些播放器初始化后的操作
  });

  // 加载和播放视频
  myPlayer.value.load();
  myPlayer.value.play();

  // 网页重新激活后，更新视频
  if (parentMsg.status == 1) {
    // 绑定播放事件

    const video1 = document.getElementById("myVideo");
    video1.addEventListener("play", function () {
      //播放开始执行的函数
      jumpToEndBuffer();
    });

    jumpTo.value = setInterval(() => {
      jumpToEndBuffer();
    }, 300 * 1000);

    window.onfocus = () => {
      if (myPlayer.value != null) {
        let end = myPlayer.value.buffered.end(0) - 1;
        myPlayer.value.currentTime = end;
      }
    };
  }
};

//设置跳帧
const jumpToEndBuffer = () => {
  let buffered = myPlayer.value.buffered;
  if (buffered.length > 0) {
    let end = buffered.end(0);
    if (end - myPlayer.value.currentTime > 0.2) {
      myPlayer.value.currentTime = end - 0.1;
    }
  }
};

//评论区
const config = reactive<ConfigApi>({
  user: {
    id: 1,
    username: "admin",
    avatar:
      "https://static.juzicon.com/avatars/avatar-200602130320-HMR2.jpeg?x-oss-process=image/resize,w_100",
    likes: [1, 2, 11],
  },
  emoji: emoji,
  comments: [],
});
// 提交评论事件
const submit = ({ clear, content, parentId }: CommentSubmitParam) => {
  if (route.query.index == 0) {
    let data = {
      content: content,
      caseId: parseInt(route.query.value),
    };
    addcommentApi(data).then((res) => {
      if (res.data.code == 200) {
        //评论区
        let data1 = {
          caseId: route.query.value,
        };
        commentApi(data1).then((res) => {
          rePlarr.value = res.data.rows;
          total.value = res.data.total;
          const text = document.querySelector(".rich-input");
          text.innerHTML = "";
        });
      }
    });
    // UToast({ message: '评论成功!', type: 'info' })
    // 提交评论 --后端接口处理
    // editSubmit(content, parentId as number)
    // clear()
  }
  if (route.query.index == 1) {
    let data = {
      content: content,
      postBarId: parseInt(route.query.value),
    };
    TbaddcommentApi(data).then((res) => {
      if (res.data.code == 200) {
        //评论区
        let data1 = {
          postBarId: route.query.value,
        };
        TbcommentApi(data1).then((res) => {
          rePlarr.value = res.data.rows;
          total.value = res.data.total;
          const text = document.querySelector(".rich-input");
          text.innerHTML = "";
        });
      }
    });
  }
};
const _parentId = ref("");
const _replyName = ref("");
const placemsg = ref("请输入内容");
const contoMsg = ref("");
//回复评论
const TomsgBtn = (value: any, id: number, name: any) => {
  _parentId.value = value.reviewId;
  _replyName.value = value.reviewName;
  inputBool.value = true;
  let input = document.querySelector(".van-field__control");
  if (id == 1) {
    placemsg.value = `回复 ${name}： `;
    contoMsg.value = `回复 ${name}： `;
  } else {
    plvalue.value = "";
  }
  // 获取输入框的值的长度
  // let length = input.value.length;
  // // 使用setSelectionRange()方法将焦点设置到文本末尾
  // input.setSelectionRange(length, length);
  nextTick(() => {
    input.focus();
    input.addEventListener("blur", function () {
      setTimeout(() => {
        inputBool.value = false;
      }, 100);
    });
  });
};

//发送回复
const childTomsg = () => {
  inputBool.value = false;
  let data = {
    roomId: parentMsg.roomId,
    content: plvalue.value,
  };
  addReviewList(data).then((res) => {
    if (res.data.code == 200) {
      plvalue.value = "";
      getreList();
    }
  });
};
</script>
  
  <style scoped>
.msgBox {
  width: 100%;
  height: calc(100vh - 100px);
  /* overflow-y: scroll; */
}
.u-comment[data-v-46e72c8f] {
  margin-top: 0;
}
.clmsg {
  color: rgb(150, 151, 153);
}
.clmsg:last-child {
  padding-bottom: 15px;
}
::v-deep .van-field__label {
  width: 50px !important;
  margin-right: 0px !important;
}
</style>
<style>
.van-field__control {
  padding-bottom: 10px !important;
}
</style>
