<template>
  <div
    style="
      width: 100%;
      height: calc(100vh - 46px);
      background: linear-gradient(
        to bottom right,
        rgb(53, 153, 219),
        rgb(56, 214, 183)
      );
    "
  >
    <div
      style="
        width: 100%;
        text-align: center;
        padding-top: 15px;
        font-size: 20px;
        font-weight: bold;
      "
    >
      术影云
    </div>
    <div class="flbox">
      <p style="text-align: center; font-weight: bold; padding: 20px">
        使用条款
      </p>
      <div style="width: 100%; height: 79%; overflow-y: scroll">
        <div style="width: 92%; height: 100%; margin: 0 auto; font-size: 14px">
          <p class="title">关于本公众号</p>
          <div>
            <br />
            &nbsp;本《使用条款》（以下简称“本条款”）适用于广东欧谱曼迪科技股份有限公司（以下简称“欧谱曼迪”、“我们”、“我们的”）拥有并运营的微信公众号：Nebula，以及我们当前或今后拥有的提及本条款的任何站点（以下简称“本公众号”）。
            在使用本公众号所提供的大量富有价值的服务之前，请先略微花一些时间确保您已理解适用于本公众号访问及使用事宜的本条款。您一旦访问或使用本公众号（包括其中所有内容及功能），即视为同意受本条款及您或将选择参加的某些计划所适用的任何附加条款的约束。若您对本条款的任何内容持有异议，请勿访问或使用本公众号。您一旦访问或使用本公众号，即同时视为同意我们的《个人信息保护政策》所含条款，该政策说明了我们收集的个人信息内容、个人信息收集及使用方法、我们的个人信息保护及披露政策，以及您可就您的个人信息所享有的权利等。
            欧谱曼迪保留随时自行酌情对本公众号及本条款作出更新或变更的权利。下方的“最后更新日期”说明显示本条款最近一次更新的日期。所有变更一经本公众号发布即告生效。修订条款发布后，若您继续使用本公众号，则表示您确认并同意受经修订的《使用条款》约束。为此，我们建议您在每次使用本公众号前均阅读本条款。我们可在本公众号个别页面上发布明确指定的法律声明或条款，用以取代本条款部分规定。
            <br />
            <pre>1.以电子形式订立合同</pre>
            <p>
              您一旦访问并使用本公众号，即视为同意以电子形式与欧谱曼迪订立合同，并同意接受本条款及我们的《个人信息保护政策》。您同意，本电子格式的《使用条款》，连同您使用本公众号所提供的服务之行为，与您的书面签字具有同等效力及效果，并符合任何规定必须采用书面形式或签字的法律（包括但不限于《中华人民共和国电子签名法》）之要求。您进一步同意，不会因本条款采用电子形式传输或获得授权而否定本条款的有效性、可执行性或可采性。您进一步确认您确有机会打印本合同。
            </p>
            <pre>2.访问限制</pre>
            <p>
              本公众号为欧谱曼迪旗下为医务工作者、医学生、我们的内部员工和合作伙伴提供医学教育与学术信息，分享行业前沿咨讯的平台。该公众号所提供的信息只能作为普通信息和学术交流目的使用。在访问和使用本公众号之前，请确认您是医务工作者、医学生、我们的内部员工或合作伙伴，并认真阅读和分析下列使用条款。如果您访问并使用了本公众号，说明您承认自己已经阅读、理解并同意遵守本条款。
              如果您不是医务工作者、医学生、我们的内部员工或合作伙伴，或不同意本条款中的内容，请关闭本公众号的相关页面并不再访问本公众号。
            </p>
            <pre>3.知识产权</pre>
            <p>
              本公众号及其内容、性能及功能（包括但不限于产品、信息、资料、文字、图形、照片、插图、视频、显示内容、图像、音频、音乐、按键图标、数据汇编、文档、软件、其他原创作品，以及上述各项的设计、甄选、编排及汇编，统称“相关内容”）所带有的和与之相关的一切权利、所有权及利益，以及本公众号的一切知识产权均由欧谱曼迪、我们的关联机构、合作伙伴或许可方（“欧谱曼迪知识产权所有权人”）持有，并受中华人民共和国（简称“中国”，为本条款之目的，不包括香港及澳门特别行政区和台湾）及其他国家或地区的知识产权法律、法规和相关国际条约保护。
            </p>
            <p>
              您仅可出于个人非商业目的访问并使用本公众号。我们并未向您转让本公众号所带有的或与之相关的任何权利、所有权或利益，同时，欧谱曼迪知识产权所有权人保留一切未明确授予的权利。除本条款有明确规定外，我们明确禁止您在未经欧谱曼迪知识产权所有权人事先明确同意的情况下，对本公众号任何组成部分或您从本公众号或通过本公众号获取的任何相关内容作下列操作：拷贝、出售、转让、许可、出租、复制、发布、修改、反汇编、反编译、逆向工程、创建衍生作品、公开展示、公开表演、营销、下载、存储、传输、披露或以其他方式加以利用。
            </p>
            <p>
              除本第3条外，若您违反本条款的规定访问或使用本公众号任何部分，或向任何其他个人或实体提供本公众号任何部分的访问权，则您使用本公众号的权利将立即终止，且您须按照我们的选择，向我们归还或销毁您制作的相关内容复本。欧谱曼迪同时保留权利，可在您违反或可能违反本条款的情况下，立即随时终止对您使用本公众号或通过本公众号提供的任何服务之授权，并删除您的一个或多个相关账号。
            </p>
            <p>
              本公众号所展示的商标、标志、服务标识及产品标识均为欧谱曼迪知识产权所有权人的注册或未注册商标，并受《中国商标法》及国际商标法律以及关于上述标识保护的任何适用法律（定义见下文）保护。本公众号中出现的并非归欧谱曼迪知识产权所有权人所有的其他商标，一律分别为该等商标所有人的财产，而该等所有人可能与我们存在或不存在关联关系、联系或由或非由我们赞助。
            </p>
          </div>
        </div>
      </div>
      <div style="text-align: center; padding-top: 10px">
        <van-button color="rgb(130,200,160)" @click="nextTo"
          >我知道了</van-button
        >
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";

const nextTo = () => history.back();
</script> 
<style >
.flbox {
  width: 90%;
  height: 80vh;
  background: white;
  border-radius: 5px;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 10px;
}
.title {
  font-weight: bold;
  font-size: 14px;
}
pre {
  font-weight: bold;
  padding: 5px 0;
}
</style>