<template>
  <div class="hello">
    <!-- <vue-pdf
      :src="pdfSrc"
      @num-pages="0"
    
    /> -->
    <PDFView :pdfUrl="pdfSrc"
             v-if="pdfSrcbool" />
  </div>
</template>
<script lang="ts" setup>
// import VuePdfApp from "vue-pdf";
import { nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import PDFView from "@/components/pdfPreview.vue";
const props = defineProps({
  pdfSrc: {
    type: String,
    required: true,
  },
});
const pdfSrc = ref(props.pdfSrc);
const pdfSrcbool = ref(false);
watch(
  () => props.pdfSrc,
  (val) => {
    pdfSrc.value = val;
    pdfSrcbool.value = true;
  }
);

// const pdfSrc = ref("http://localhost:8080/nebula-web/1.pdf");
</script>
<style scoped>
.hello {
  width: 100%;
  /* height: 50vh; */
  /* position: absolute;
  top: 50;
  z-index: 1000; */
}
</style>