import request from "@/utils/request";

export function swImage() {
    return request({
        url: "/system/wxHome/imageCarousel",
        method: "get",
    })
}
export function contentList() {
    return request({
        url: "/bbs/wxHome/bbsContentList",
        method: "get",
    })
}
//搜索历史
export function selectHisList(data: any) {
    return request({
        url: "/system/historySearch/list",
        method: 'get',
        params: data
    })
}
//删除历史
export function delHisList(data: any) {
    return request({
        url: "/system/historySearch/" + data,
        method: 'delete',
    })
}
//搜索
export function getHisList(data: any) {
    return request({
        url: "/system/historySearch/content",
        method: 'get',
        params: data
    })
}