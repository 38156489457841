import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'dayjs/locale/zh-cn';
import locale from 'element-plus/es/locale/lang/zh-cn';

import Vant from 'vant';
import 'vant/lib/index.css';

import UndrawUi from 'undraw-ui'
import 'undraw-ui/dist/style.css'

// console.log('url',process.env.VUE_APP_BASE_API);



createApp(App).use(store).use(router).use(Vant).use(UndrawUi).use(ElementPlus, { locale }).mount('#app')
