<template>
  <div>
    <van-pull-refresh
      v-model="loading"
      @refresh="onRefresh"
      :disabled="freshBool"
    >
      <div class="msgbox" @scroll="handleScroll" v-loading="loadBool">
        <van-card
          :title="
            item.businessType == 1
              ? item.bbsCase.title
              : item.businessType == 2
              ? item.bbsPostBar.title
              : item.bbsLiterature.title
          "
          :thumb="imgUrl[index]"
          @click="nextToMsg(item.businessId, item.businessType - 1)"
          v-for="(item, index) in collData"
          :key="index"
        >
          <template #thumb>
            <img :src="imgUrl[index]" style="width: 40vw; height: 100%" />
          </template>
          <template #tags>
            <div style="padding-top: 10px">
              <span
                style="
                  margin-right: 5px;
                  font-size: 12px;
                  color: rgb(152, 152, 152);
                "
                v-if="item.businessType == 1"
                >案例</span
              >
              <span
                style="
                  margin-right: 5px;
                  font-size: 12px;
                  color: rgb(152, 152, 152);
                "
                v-if="item.businessType == 2"
                >贴吧</span
              >
              <span
                style="
                  margin-right: 5px;
                  font-size: 12px;
                  color: rgb(152, 152, 152);
                "
                v-if="item.businessType == 3"
                >文献</span
              >
              <span
                style="
                  margin-right: 5px;
                  font-size: 12px;
                  color: rgb(143, 195, 32);
                "
                v-if="item.bbsCase.type == 1"
                >图文</span
              >
              <span
                style="
                  margin-right: 5px;
                  font-size: 12px;
                  color: rgb(143, 195, 32);
                "
                v-if="item.bbsCase.type == 2"
                >视频</span
              >
              <span
                style="
                  margin-right: 5px;
                  font-size: 12px;
                  color: rgb(143, 195, 32);
                "
                v-if="item.bbsCase.type == 3"
                >文本</span
              >
              <span
                style="
                  margin-right: 5px;
                  font-size: 12px;
                  color: rgb(143, 195, 32);
                "
                v-if="item.bbsPostBar.type == 1"
                >图文</span
              >
              <span
                style="
                  margin-right: 5px;
                  font-size: 12px;
                  color: rgb(143, 195, 32);
                "
                v-if="item.bbsPostBar.type == 2"
                >视频</span
              >
              <span
                style="
                  margin-right: 5px;
                  font-size: 12px;
                  color: rgb(143, 195, 32);
                "
                v-if="item.bbsPostBar.type == 3"
                >文本</span
              >
            </div>
            <div style="padding-top: 10px">
              <div v-if="item.businessType == 1">
                <van-button
                  v-show="item.collectFlag == 'false'"
                  icon="star"
                  size="mini"
                  @click="FavoriteBtn(item.bbsCase.caseId, 1, index)"
                  >收藏</van-button
                >
                <van-button
                  v-show="item.collectFlag != 'false'"
                  style="padding: 0 10px"
                  round
                  color="linear-gradient(
    to right,
    rgb(130, 200, 160),
    rgb(223, 236, 156)
  )"
                  icon="star"
                  size="mini"
                  @click="FavoriteBtn(item.bbsCase.caseId, 1, index)"
                  >已收藏</van-button
                >
              </div>
              <div v-if="item.businessType == 2">
                <van-button
                  v-show="item.collectFlag == 'false'"
                  icon="star"
                  size="mini"
                  @click="FavoriteBtn(item.bbsPostBar.postBarId, 2, index)"
                  >收藏</van-button
                >
                <van-button
                  v-show="item.collectFlag != 'false'"
                  style="padding: 0 10px"
                  round
                  color="linear-gradient(
    to right,
    rgb(130, 200, 160),
    rgb(223, 236, 156)
  )"
                  icon="star"
                  size="mini"
                  @click="FavoriteBtn(item.bbsPostBar.postBarId, 2, index)"
                  >已收藏</van-button
                >
              </div>
              <div v-if="item.businessType == 3">
                <van-button
                  v-show="item.collectFlag == 'false'"
                  icon="star"
                  size="mini"
                  @click="
                    FavoriteBtn(item.bbsLiterature.literatureId, 3, index)
                  "
                  >收藏</van-button
                >
                <van-button
                  v-show="item.collectFlag != 'false'"
                  style="padding: 0 10px"
                  round
                  color="linear-gradient(
    to right,
    rgb(130, 200, 160),
    rgb(223, 236, 156)
  )"
                  icon="star"
                  size="mini"
                  @click="
                    FavoriteBtn(item.bbsLiterature.literatureId, 3, index)
                  "
                  >已收藏</van-button
                >
              </div>
            </div>
          </template>
        </van-card>
      </div>
    </van-pull-refresh>
  </div>
</template>
    
<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { CollList } from "@/api/user";
import { collLikeApi, cancelCollLikeApi } from "@/api/forum";
const router = useRouter();
import { showToast } from "vant";
const active = ref(0);
const freshBool = ref(false);
const collData = ref([]);
const forumData = ref([]);
const onClickLeft = () => history.back();
const loading = ref(false);
const loadBool = ref(false);
const detailsBool = ref(true);
const favorBtncolor = ref("");
const likeBtncolor = ref("");
const imgUrl = ref([]);
watch(active, (newValue, oldValue) => {
  localStorage.setItem("active", newValue);
});
onMounted(() => {
  let avtBool = localStorage.getItem("active");
  if (avtBool == null || avtBool == 0) {
    active.value = 0;
  } else {
    active.value = 1;
  }
  getCollList();
});

//收藏列表
const getCollList = () => {
  loadBool.value = true
  let data = {
    pageNum: 1,
    pageSize: 100,
    // operatorType: 3,
    collectType: true,
  };
  CollList(data).then((res) => {
    if (res.status == 200) {
      imgUrl.value = [];
      collData.value = res.data.data;
      collData.value.forEach((item, index) => {
        if (item.bbsCase == null && item.bbsLiterature == null) {
          collData.value[index].bbsCase = { filesList: [""] };
          collData.value[index].bbsLiterature = { filesList: [""] };
          if (item.bbsPostBar.type == 2) {
            imgUrl.value.push(item.bbsPostBar.videoTitle);
          }
          if (item.bbsPostBar.type == 1) {
            imgUrl.value.push(item.bbsPostBar.filesList[0].url);
          }
        }
        if (item.bbsPostBar == null && item.bbsLiterature == null) {
          collData.value[index].bbsPostBar = { filesList: [""] };
          collData.value[index].bbsLiterature = { filesList: [""] };
          if (item.bbsCase.type == 2) {
            imgUrl.value.push(item.bbsCase.videoTitle);
          }
          if (item.bbsCase.type == 1) {
            imgUrl.value.push(item.bbsCase.filesList[0].url);
          }
          if (item.bbsCase.type == 3) {
            imgUrl.value.push(require("@/assets/文件.png"));
          }
        }
        if (item.bbsPostBar == null && item.bbsCase == null) {
          collData.value[index].bbsPostBar = { filesList: [""] };
          collData.value[index].bbsCase = { filesList: [""] };
          imgUrl.value.push(item.bbsLiterature.literatureCoverPictureUrl);
        }
      });
      setTimeout(()=>{
        loadBool.value = false
      },1000)
    }
  });
};

//刷新
const onRefresh = () => {
  let data = {
    pageNum: 1,
    pageSize: 100,
    // operatorType: 3,
    collectType: true,
  };
  //
  CollList(data)
    .then((res) => {
      if (res.status == 200) {
        imgUrl.value = [];
        collData.value = res.data.data;
        collData.value.forEach((item, index) => {
          if (item.bbsCase == null && item.bbsLiterature == null) {
            collData.value[index].bbsCase = { filesList: [""] };
            collData.value[index].bbsLiterature = { filesList: [""] };
            if (item.bbsPostBar.type == 2) {
              imgUrl.value.push(item.bbsPostBar.videoTitle);
            }
            if (item.bbsPostBar.type == 1) {
              imgUrl.value.push(item.bbsPostBar.filesList[0].url);
            }
          }
          if (item.bbsPostBar == null && item.bbsLiterature == null) {
            collData.value[index].bbsPostBar = { filesList: [""] };
            collData.value[index].bbsLiterature = { filesList: [""] };
            if (item.bbsCase.type == 2) {
              imgUrl.value.push(item.bbsCase.videoTitle);
            }
            if (item.bbsCase.type == 1) {
              imgUrl.value.push(item.bbsCase.filesList[0].url);
            }
            if (item.bbsCase.type == 3) {
              imgUrl.value.push(require("@/assets/文件.png"));
            }
          }
          if (item.bbsPostBar == null && item.bbsCase == null) {
            collData.value[index].bbsPostBar = { filesList: [""] };
            collData.value[index].bbsCase = { filesList: [""] };
            imgUrl.value.push(item.bbsLiterature.literatureCoverPictureUrl);
          }
        });
        showToast("刷新成功");
        loading.value = false;
      }
    })
    .catch((err) => {
      showToast("刷新失败");
      loading.value = false;
    });
};
const onClickRight = () => {
  router.push({
    path: "/ReleaseIndex",
    query: {
      value: active.value,
    },
  });
};
//滚动条触顶
const handleScroll = (event) => {
  const scrollTop = event.target.scrollTop;
  if (scrollTop == 0) {
    freshBool.value = false;
  } else {
    freshBool.value = true;
  }
};
//进入详情
const nextToMsg = (value, index) => {
  router.push({
    path: "/TiebMsg",
    query: {
      value: value,
      index: index,
    },
  });
};
// 收藏
const FavoriteBtn = (value: any, type: any, index: any) => {
  window.event.cancelBubble = true; //取消事件的冒泡机制
  console.log(value, type, index);

  if (type == 1) {
    if (collData.value[index].collectFlag == "false") {
      let data = {
        businessId: value,
        businessType: type,
        operatorType: 3,
      };
      collLikeApi(data).then((res) => {
        console.log(res);
        getCollList();
      });
    } else {
      let data = {
        businessId: value,
        businessType: type,
        operatorType: 3,
      };
      cancelCollLikeApi(data).then((res) => {
        console.log(res);
        getCollList();
      });
    }
  }
  if (type == 2) {
    if (collData.value[index].collectFlag == "false") {
      let data = {
        businessId: value,
        businessType: type,
        operatorType: 3,
      };
      collLikeApi(data).then((res) => {
        console.log(res);
        getCollList();
      });
    } else {
      let data = {
        businessId: value,
        businessType: type,
        operatorType: 3,
      };
      cancelCollLikeApi(data).then((res) => {
        console.log(res);
        getCollList();
      });
    }
  }
  if (type == 3) {
    if (collData.value[index].collectFlag == "false") {
      let data = {
        businessId: value,
        businessType: type,
        operatorType: 3,
      };
      collLikeApi(data).then((res) => {
        console.log(res);
        getCollList();
      });
    } else {
      let data = {
        businessId: value,
        businessType: type,
        operatorType: 3,
      };
      cancelCollLikeApi(data).then((res) => {
        console.log(res);
        getCollList();
      });
    }
  }
};
</script>
<style scoped>
.msgbox {
  width: 96%;
  height: calc(100vh - 60px);
  overflow-y: scroll;
  margin: 5px auto 0 auto;
  padding: 5px;
}
::v-deep .van-card__content > div {
  padding-left: calc(100% - 45vw);
}
</style>
<style>
.van-button__text {
  padding-top: 2px;
}
</style>